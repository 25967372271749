import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage from '../assets/img/navImage.svg'
import home2 from '../assets/img/home-2.svg'
import profilePic from '../assets/img/profilePic.svg'
import PlayButton from '../assets/img/PlayButton.svg'
import rightNevigate from '../assets/img/rightNavigateWhite.svg'
import redrightNevigate from '../assets/img/rightNevigate.svg'

export default function VideoBlog() {
    return (
        <>
            <div className='w-screen bg-offwhite'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='w-full sm:h-[300px] h-[250px] flex text-white bg-cover' style={{ backgroundImage: `url(${navImage})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='mt-auto md:container sm:mx-5 px-5 md:px-0 xl:w-[1170px] md:mx-auto'>
                            <div className='w-full sm:md-0 mb-10'>
                                <h1 className='md:text-6xl text-4xl font-bold' >VIDEO BLOG</h1>
                                <p className='mt-5 md:text-2xl text-sm text-para'>Lorem ipsum dolor sit amet, scelerisque turpis.</p>
                            </div>
                        </div>
                    </div>

                    <div className='px-5 py-20 min-[1920px]:w-[1920px] w-full mx-auto bg-white'>
                        <div className='min-[1170px]:w-[1170px] md:container md:mx-auto w-full bg-white'>

                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10'>
                                <div>
                                    <div className='p-5 h-[360px] w-full bg-cover text-white text-sm flex flex-col justify-between' style={{ backgroundImage: `url(${home2})` }}>
                                        <div className='flex items-center'>
                                            <img src={profilePic} alt="" className='h-12 me-3' />
                                            <div className=''>
                                                <p>Jonathan Loremip</p>
                                                <p>12.06.2020</p>
                                            </div>
                                        </div>
                                        <img src={PlayButton} alt="" className='h-16' />
                                        <div className=''>
                                            <span className='p-1 px-2 bg-red-500'>#USAPICKUP</span>
                                        </div>
                                    </div>
                                    <div className='mt-5 flex items-center'>
                                        <p className='text-[24px] font-semibold'>Lorem ipsum dolor sit amet, consectetur viverra vitae.</p>
                                        <button className='ms-auto py-3 px-4 me-2 bg-red'><img src={rightNevigate} alt="" className='md:h-6 h-4 md:w-6 w-4' /></button>
                                    </div>
                                </div>

                                <div className=' w-full'>
                                    <div className='sm:flex justify-between items-center '>
                                        <p className='text-4xl py-3 font-semibold'>MOST RECENT</p>
                                        <button className='p-2 px-5 text-sm text-red font-bold border-2 border-red-500'>SHOW MORE</button>
                                    </div>
                                    <div className=''>
                                        {
                                            Array.from({ length: 3 }).map((index) => {
                                                return <div className='mt-5 flex  items-center' key={index}>
                                                    <img src={home2} alt="" className='h-[100px] w-[163px] object-cover' />
                                                    <div className='mx-5'>
                                                        <p className='text-red font-semibold'>LOREM IPSUM</p>
                                                        <p className='font-semibold'>Lorem ipsum dolor sit amet, consectetur viverra vitae.</p>
                                                    </div>
                                                    <img src={redrightNevigate} alt="" className='sm:block hidden md:ms-10 ms-5' />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='min-[1170px]:w-[1170px] md:container md:mx-auto py-14 md:px-0 px-5 w-full'>
                        <div className='sm:flex justify-between sm:space-y-0 space-y-3'>
                            <p className='text-4xl font-bold'>ALL LOREMIP</p>
                            <div className='flex items-center'>
                                <p className='text-para me-5'>Sort by</p>
                                <select name="" id="" className='py-2 pe-28 ps-3'>
                                    <option value="">New</option>
                                </select>
                            </div>
                        </div>
                        <div className='my-10 grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10'>

                            {
                                Array.from({ length: 9 }).map((index) => {
                                    return <div className=' bg-white shadow-lg' key={index}>
                                        <div className='h-[225px] bg-cover flex justify-center items-center' style={{ backgroundImage: `url(${home2})`, backgroundRepeat: 'no-repeat' }}>
                                            <img src={PlayButton} alt="" className='h-9' />
                                        </div>
                                        <div className=' px-5'>
                                            <p className='my-3 text-lg font-medium'>Lorem ipsum dolor sit amet, consecte  viverra vitae.</p>
                                            <div className=' flex items-center my-3 text-sm'>
                                                <img src={profilePic} alt="" />
                                                <div className='mx-2'>
                                                    <p>Jonathan Loremip</p>
                                                    <p className='text-para'>12.06.2020</p>
                                                </div>
                                                <button className='ms-auto py-3 px-4 bg-red'><img src={rightNevigate} alt="" className='md:h-6 h-4 md:w-6 w-4' /></button>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }


                        </div>
                    </div>

                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
