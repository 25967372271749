import React, { useState } from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage2 from '../assets/img/navImage2.svg'
import redGreaterthan from '../assets/img/greaterthan-red.svg'

import tire from '../assets/img/home-tire.svg'
import bookmarkLogo from '../assets/img/bookmarkLogo.svg'
import rightNevigate from '../assets/img/rightNevigate.svg'
import cartLogo from '../assets/img/addToCart-logo.svg'
import vehicle1 from '../assets/img/vehicle-1.svg'
import greaterthanRed from '../assets/img/greaterthan-red.svg'
import { useNavigate } from 'react-router-dom'
import FilterOptions from '../shared/FilterOptions'

export default function SearchByVehicle() {

    const navigate = useNavigate()
    const [filter, setFilter] = useState(false)

    return (
        <>
            <div className='w-screen'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto bg-offwhite'>

                    <div className='w-full md:h-[260px] h-[230px] text-white bg-cover' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='md:hidden text-white mb-7 mx-5 h-full flex flex-col'>
                            <div className='mt-auto mb-5'>
                                <div className='md:hidden flex mb-2'>
                                    <img src={redGreaterthan} alt="" className='h-5 me-5 rotate-180' />
                                    <p>SEARCH BY VEHICLE</p>
                                </div>
                                <h1 className=' font-bold sm:text-4xl text-3xl' >RESULTS ON PAGE</h1>
                            </div>
                        </div>
                    </div>

                    <div className='min-[1170px]:w-[1170px] md:container md:-mt-28 text-white mb-7 mx-5 md:flex md:mx-auto z-10'>

                        <div className='md:me-5'>
                            <div className='md:flex hidden items-center space-x-7'>
                                <h1 className='font-medium text-lg' >SEARCH BY VEHICLE</h1>
                                <img src={redGreaterthan} alt="" className='h-4' />
                                <p className='font-light'>Ford F150</p>
                            </div>
                            <div className='mt-5 p-2 sm:flex bg-white text-black'>
                                <img src={vehicle1} alt="" className='sm:h-40 h-full sm:w-48 w-full bg-cover' />
                                <div className='sm:px-5 px-3 py-2'>
                                    <div className='flex'>
                                        <p className='font-semibold sm:pe-20'>2015 FORD F-150 WHEELS & RIMS</p>
                                        <img src={redGreaterthan} alt="" className='rotate-90 ms-auto h-5' />
                                    </div>
                                    <p className='my-2 text-red-500'>Recommended Fitment</p>
                                    <div className='flex flex-col space-y-1 text-sm'>
                                        <div className='flex'>
                                            <p className='text-para'>Product SKU</p>
                                            <p className='ms-auto font-semibold'>TIS 544BM</p>
                                        </div>
                                        <div className='flex'>
                                            <p className='text-para'>Wheel dimension</p>
                                            <p className='ms-auto font-semibold'>20x10 - 25mm</p>
                                        </div>
                                        <div className='flex'>
                                            <p className='text-para'>Color</p>
                                            <p className='ms-auto font-semibold'>Black Milled</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* for big screen filter button*/}
                        <div className='md:flex hidden ms-auto h-14'>
                            <div className='flex items-center'>
                                <p className='me-5 text-para md:block hidden text-nowrap'>Sort by</p>
                                <select name="" id="" className='py-1 lg:pe-20 pe-5 ps-3 bg-white text-black'>
                                    <option value="">Lowest Prize</option>
                                </select>
                            </div>
                            <div className='flex items-center ' onClick={() => { setFilter(!filter) }}>
                                <p className='lg:ms-16 ms-5 me-2'>FILTER</p>
                                <img src={redGreaterthan} alt="" className={`h-6 w-6 ${!filter ? 'rotate-90' : '-rotate-90'}`} />
                            </div>
                        </div>

                        {/* small screen filter button and fetch tire detail */}
                        <div className='md:hidden block mt-5'>
                            <div className='w-full h-full'>
                                <div className='flex justify-center'>
                                    <div className='flex items-center px-5 py-2 bg-red-500' onClick={() => { setFilter(!filter) }}>
                                        <p className='me-2'>FILTER</p>
                                        <p className={`font-bold text-2xl ${filter?'rotate-90':'-rotate-90'}`}>&gt;</p>
                                    </div>
                                </div>
                                <div className=' px-5 w-full h-40 text-black'>
                                    <div className='py-5 h-full space-y-3'>
                                        <div className='flex items-center justify-center'>
                                            <p className='me-2 font-medium'>Total search results for "Wheel"</p>
                                            <p className='text-red-500 font-medium'>129</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='me-auto text-para'>Resoults on page</p>
                                            <select name="" id="" className='px-3 py-1 border'>
                                                <option value="">30</option>
                                            </select>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='me-auto text-para'>Sort by</p>
                                            <select name="" id="" className='px-3 py-1 border'>
                                                <option value="">Lowest Prize</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            filter &&
                            <FilterOptions setFilter={setFilter}/>
                        }
                    </div>

                    <div className='py-10 w-full '>
                        <div className='min-[1170px]:w-[1170px] w-full mx-auto border-green-600 '>

                            <div className='px-5 md:container mx-auto grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-14 gap-10'>

                                {
                                    Array.from({ length: 9 }).map((index) => {
                                        return (
                                            <div className='py-3 lg:px-8 px-5 w-full flex flex-col shadow-2xl bg-white' key={index}>
                                                <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                                <div className='flex justify-center'>
                                                    <img src={tire} alt="" className='' />
                                                </div>
                                                <p className='mt-5'>American Force Carnage Concave Wheels</p>
                                                <div className='mt-2 flex items-center'>
                                                    <p className='text-xl font-semibold text-red-500'>$4,351</p>
                                                    <button className='ms-auto p-2 me-2 border-2 border-red-600' onClick={() => { navigate('/CheckOutMultipleItem') }}><img src={rightNevigate} alt="" className='md:h-6 h-4 md:w-6 w-4' /></button>
                                                    <img src={cartLogo} alt="" className='h-11 w-11' />
                                                </div>
                                                <div className='mt-5'>
                                                    <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Product SKU</p><p className='font-semibold'>TIS 544BM</p></div>
                                                    <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Wheel dimension</p><p className='font-semibold'>20x10 - 25mm</p></div>
                                                    <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Color</p><p className='font-semibold'>Black Milled</p></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            <hr className='border mt-10 mb-5' />
                            <div className='flex md:justify-end justify-center items-center space-x-5'>
                                <p className='md:block hidden'>page</p>
                                <button><img src={greaterthanRed} alt="" className='rotate-180' /></button>
                                <button className='h-full w-7 hover:bg-white'>1</button>
                                <button className='h-full w-7 hover:bg-white'>2</button>
                                <button className='h-full w-7 hover:bg-white'>3</button>
                                <button className='text-3xl'><img src={greaterthanRed} alt="" /></button>
                            </div>

                        </div>

                    </div>


                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
