import React from 'react'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import Navbar from '../shared/Navbar'
import completeIcon from '../assets/img/orderCompleteIcon.svg'
import navImage2 from '../assets/img/navImage2.svg'
import { useNavigate } from 'react-router-dom'

export default function OrderCompleted() {
    const navigate = useNavigate()
    return (
        <>
            <div className='w-screen '>

                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto'>

                    <div className='w-full h-[200px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='min-[1170px]:w-[1170px] md:container mx-5  md:mx-auto flex pt-36  h-full'>
                            <h1 className='text-center sm:text-start font-semibold text-4xl text-red' >MY CART </h1>
                        </div>
                    </div>

                    <div className='sm:my-20 my-16 space-y-6 flex flex-col items-center justify-center'>
                        <img src={completeIcon} alt="" className='h-32 w-28'/>
                        <p className='font-medium text-2xl text-center'>Your order has been successfully received! </p>
                        <p className='text-slate-700'>Your order ID number is <span className='text-red'>58</span></p>
                        <button className='bg-red font-bold text-lg text-white px-10 sm:py-5 py-3' onClick={()=>{navigate('/requestProduct')}}>BACK TO SHOP</button>
                    </div>

                </div>


                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
                
            </div>
        </>
    )
}
