import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage3 from '../assets/img/navImage3.svg'
import mobilePhoneIcon from '../assets/img/mobilePhoneIcon.svg'
import mailIcon from '../assets/img/mailIcon.svg'
import redInstaIcon from '../assets/img/redInstaIcon.svg'
import youtubeIcon from '../assets/img/youtubeIcon.svg'

export default function Contact() {
    return (
        <>
            <div className='w-screen bg-offwhite'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='w-full sm:h-[300px] h-[250px] flex text-white bg-cover' style={{ backgroundImage: `url(${navImage3})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='mt-auto md:container sm:mx-5 px-5 md:px-0 xl:w-[1170px] md:mx-auto'>
                            <div className='w-full sm:md-0 mb-10'>
                                <h1 className='md:text-6xl text-4xl font-bold' >CONTACT US</h1>
                                <p className='mt-5 md:text-2xl text-sm text-para'>Lorem ipsum dolor sit amet, scelerisque turpis.</p>
                            </div>
                        </div>
                    </div>

                    <div className='min-[1170px]:w-[1170px] md:container md:mx-auto py-14  w-full'>

                        <div className='grid md:grid-cols-2 grid-cols-1 '>

                            <div className='md:px-0 px-5'>

                                <div className='flex'>
                                    <img src={mobilePhoneIcon} alt="" className='mt-1  h-5 w-5' />
                                    <div className='ms-5 '>
                                        <p className='text-lg text-para'>PHONE</p>
                                        <p className='mt-2 sm:text-4xl text-2xl font-semibold'>610-426-3025</p>
                                    </div>
                                </div>
                                <div className='mt-12 flex'>
                                    <img src={mailIcon} alt="" className='mt-1  h-5 w-5' />
                                    <div className='ms-5 '>
                                        <p className='text-lg text-para'>EMAIL:</p>
                                        <p className='mt-2 lg:text-2xl  font-semibold'>STANDOUTSPECIALTIES@GMAIL.COM</p>
                                    </div>
                                </div>
                                <div className='mt-12 flex'>
                                    <img src={redInstaIcon} alt="" className='mt-1  h-5 w-5' />
                                    <div className='ms-5 '>
                                        <p className='text-lg text-para'>INSTAGRAM:</p>
                                        <p className='mt-2 sm:text-2xl font-semibold'>@STANDOUTSPECIALTIES</p>
                                    </div>
                                </div>
                                <div className='mt-12 flex'>
                                    <img src={youtubeIcon} alt="" className='mt-1  h-5 w-5' />
                                    <div className='ms-5 '>
                                        <p className='text-lg text-para'>YOUTUBE: </p>
                                        <p className='mt-2 sm:text-2xl font-semibold'>AARON ROBERTSON</p>
                                    </div>
                                </div>

                            </div>

                            <div className='md:px-10 md:mt-0 mt-10'>
                                <div className='w-full p-10 bg-[#161718] text-white text-xl text-para space-y-6'>
                                    <div className='bg-white p-2'>
                                        <input type='text' placeholder='Name' className='px-2 w-full focus:outline-none' />
                                    </div>
                                    <div className='bg-white p-2'>
                                        <input type='text' placeholder='Email' className='px-2 w-full focus:outline-none' />
                                    </div>
                                    <div className='bg-white p-2'>
                                        <input type='text' placeholder='Phone Number' className='px-2 w-full focus:outline-none' />
                                    </div>
                                    <div className='bg-white p-2'>
                                        <textarea rows={5} placeholder='Message' className='px-2 w-full focus:outline-none' />
                                    </div>
                                    <button className='w-full bg-red p-2 text-white'>SEND</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
