import React from 'react'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import Navbar from '../shared/Navbar'
import { useNavigate } from 'react-router-dom'
import navImage2 from '../assets/img/navImage2.svg'
import tire from '../assets/img/home-tire.svg'
import bookmarkLogo from '../assets/img/bookmarkLogo.svg'
import rightNevigate from '../assets/img/rightNevigate.svg'
import cartLogo from '../assets/img/addToCart-logo.svg'
import greaterthanRed from '../assets/img/greaterthan-red.svg'
import brand1 from '../assets/img/brand1.svg'

export default function BrandItems() {

    const navigate = useNavigate()

    return (
        <>
            <Navbar />
            <div className='w-screen bg-[#F3F3F3]'>

                <div className='min-[1920px]:w-[1920px] w-full mx-auto '>


                    <div className='w-full sm:h-[350px] h-[250px] flex text-white bg-cover' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='mt-auto md:container sm:mx-5 px-5 md:px-0 xl:w-[1170px] md:mx-auto flex justify-between items-center'>
                            <div className='w-full sm:md-0 mb-7'>
                                <h1 className='md:text-6xl text-4xl font-bold' onClick={()=>navigate('/ShopByCategory')}>FEDERAL</h1>
                                <p className='mt-5 md:text-2xl text-sm text-para'>Lorem ipsum dolor sit amet, scelerisque turpis.</p>
                            </div>
                            <div className='sm:block hidden'>
                                <img src={brand1} alt="" className='h-[200px] w-[360px] object-cover' />
                            </div>
                        </div>
                    </div>

                    <hr className='block sm:hidden sm:border-t-2 border-t-8 border-red-500 border w-full ' />

                    <div className='w-full h-full bg-white border'>
                        <div className='px-5 min-[1170px]:w-[1170px] w-full mx-auto  text-black bg-white'>
                            <div className='py-5 sm:py-0 md:container mx-auto sm:grid grid-cols-3 md:gap-14 gap-10 h-full sm:space-y-0 space-y-3'>
                                <div className='flex justify-center sm:hidden'>
                                    <img src={brand1} alt="" className='h-[200px] w-[360px] object-cover' />
                                </div>
                                <div className='flex items-center sm:justify-start justify-center'>
                                    <p className='me-2'>Total search results</p>
                                    <p className='text-red-600'>129</p>
                                    <div className="sm:block hidden h-10 w-px bg-gray-400 ms-10"></div>
                                </div>
                                <div className='flex items-center'>
                                    <p className='sm:ms-auto me-auto sm:me-5'>Resoults on page</p>
                                    <select name="" id="" className='px-3 py-1 border'>
                                        <option value="">30</option>
                                        <option value="">32</option>
                                        <option value="">34</option>
                                        <option value="">36</option>
                                    </select>
                                </div>
                                <div className='flex items-center'>
                                    <p className='sm:ms-auto me-auto md:me-5'>Resoults on page</p>
                                    <select name="" id="" className='md:px-3 py-1 border'>
                                        <option value="">Lowest Prize</option>
                                        <option value="">Lowest Prize</option>
                                        <option value="">Lowest Prize</option>
                                        <option value="">Lowest Prize</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='py-10 w-full '>
                        <div className='min-[1170px]:w-[1170px] w-full mx-auto border-green-600 '>

                            <div className='px-5 md:container mx-auto grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-14 gap-10'>

                                {
                                    Array.from({ length: 9 }).map((item, index) => {
                                        return (
                                            <div className='py-3 lg:px-8 px-5 w-full flex flex-col shadow-2xl bg-white' key={index}>
                                                <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                                <div className='flex justify-center'>
                                                    <img src={tire} alt="" className='' />
                                                </div>
                                                <p className='mt-5'>American Force Carnage Concave Wheels</p>
                                                <div className='mt-2 flex items-center'>
                                                    <p className='text-xl font-semibold text-red-500'>$4,351</p>
                                                    <button className='ms-auto p-2 me-2 border-2 border-red-600' onClick={() => { navigate('/Checkout') }}><img src={rightNevigate} alt="" className='md:h-6 h-4 md:w-6 w-4' /></button>
                                                    <img src={cartLogo} alt="" className='h-11 w-11 border' />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <hr className='border mt-10 mb-5' />
                            <div className='flex sm:justify-end justify-center items-center space-x-5'>
                                <p className='sm:block hidden'>page</p>
                                <button><img src={greaterthanRed} alt="" className='rotate-180' /></button>
                                <button className='h-full w-7 hover:bg-white'>1</button>
                                <button className='h-full w-7 hover:bg-white'>2</button>
                                <button className='h-full w-7 hover:bg-white'>3</button>
                                <button className='h-full w-7 hover:bg-white'>4</button>
                                <button className='text-3xl'><img src={greaterthanRed} alt="" /></button>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div className='sm:block hidden'>
                <Footer1 />
            </div>
            <div className='block sm:hidden'>
                <Footer />
            </div>

        </>
    )
}
