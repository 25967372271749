import React from 'react'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import navImage3 from '../assets/img/navImage3.svg'

export default function RequestProduct() {
    return (
        <>
            <div className='w-screen'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto'>

                    <div className='w-full h-[300px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage3})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='min-[1170px]:w-[1170px] md:container mx-5  md:mx-auto pt-36  h-full'>
                            <h1 className='font-bold sm:text-6xl text-3xl' >REQUEST A QUOTE</h1>
                            <p className='mt-3 sm:text-2xl text-stone-500'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit elit volutpat.</p>
                        </div>
                    </div>

                    <div className='min-[1170px]:w-[1170px] md:container md:mx-auto mx-5 h-full'>
                        <div className='grid md:grid-cols-2 py-14'>

                            <div className='lg:me-10'>

                                <div className=''>
                                    <div className='flex items-center'>
                                        <span className='font-medium whitespace-nowrap me-4 text-red'>PERSONAL INFO</span>
                                        <hr className='border border-red-500 w-full' />
                                    </div>
                                    <div className='space-y-8 my-5'>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Name</p>
                                            <input type="text" placeholder='Lowest price' className='px-5 py-1 ms-auto bg-offwhite border w-full sm:w-auto' />
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Phone number</p>
                                            <input type="text" placeholder='Lowest price' className='px-5 py-1 ms-auto bg-offwhite border w-full sm:w-auto' />
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Email</p>
                                            <input type="text" placeholder='Lowest price' className='px-5 py-1 ms-auto bg-offwhite border w-full sm:w-auto' />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-10 '>
                                    <div className='flex items-center'>
                                        <span className='font-medium whitespace-nowrap me-4 text-red'>PERSONAL INFO</span>
                                        <hr className='border border-red-500 w-full' />
                                    </div>
                                    <div className='space-y-8 my-5'>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Year</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Make</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Model</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>

                                <div className='mt-10 '>
                                    <div className='flex items-center'>
                                        <span className='font-medium whitespace-nowrap me-4 text-red'>PERSONAL INFO</span>
                                        <hr className='border border-red-500 w-full' />
                                    </div>
                                    <div className='space-y-8 my-5'>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Size</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Width</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Wheel Brand</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Wheel Model</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Tire Brand</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                        <div className='sm:flex w-full items-center'>
                                            <p className='mb-2 text-para'>Tire Model</p>
                                            <select name="" id="" className='pe-28 ps-2 py-1 ms-auto bg-offwhite border w-full sm:w-auto'>
                                                <option value="">Lowest price</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-10 '>
                                    <div className='flex items-center'>
                                        <span className='font-medium whitespace-nowrap me-4 text-red'>PERSONAL INFO</span>
                                        <hr className='border border-red-500 w-full' />
                                    </div>
                                    <div className='my-5 space-y-5'>
                                        <p className='text-para'>Comments</p>
                                        <textarea name="" id="" placeholder='Lowest Prize' rows={5} className='p-2 border w-full bg-offwhite'></textarea>
                                        <button className='px-20 py-2 font-medium bg-red text-white'>ADD TO CART</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>



                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>

            </div >
        </>
    )
}
