import React from 'react'
import greaterthan from '../assets/img/greaterthan.svg'
import lessthan from '../assets/img/lessthan.svg'
import tire from '../assets/img/home-tire.svg'
import tire2 from '../assets/img/popular-tire-2.svg'
import tire3 from '../assets/img/popular-tire-3.svg'
import tire4 from '../assets/img/popular-tire-4.svg'
import tire5 from '../assets/img/popular-tire-5.svg'

export default function PopularWheels() {

    const wheels = [
        {
            name:'ARKON OFF-ROAD CAESAR',
            img: tire
        },
        {
            name:'ARKON OFF-ROAD LINCOLN',
            img:tire2
        },
        {
            name:'TIS 547BM',
            img: tire3
        },
        {
            name:'MOTO METAL M0970',
            img:tire4
        },
        {
            name:'HOSTILE SPROCKET',
            img:tire5
        }
    ]

    return (
        <>
            <div className='min-[1630px]:w-[1630px] 2xl:w-[1400px] w-full flex justify-evenly'>

                <div className='md:flex px-10 hidden items-center justify-center'><img src={lessthan} alt="" /></div>
                <div className='w-full flex  overflow-x-scroll space-x-10  scroll-none'>
                    {
                        wheels.map((item,index) => {
                            return (
                                <div className='p-7 flex flex-col flex-shrink-0 items-center justify-between bg-white h-[350px] w-[250px]' key={index}>
                                    <p className='font-bold text-center'>{item.name}</p>
                                    <img src={item.img} alt="" className='' />
                                    <button className='px-5 py-2 me-4  border-2 border-red-500 text-red font-bold'>SHOW MORE</button>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='md:flex px-10 hidden items-center justify-center '><img src={greaterthan} alt="" /></div>

            </div>
        </>
    )
}
