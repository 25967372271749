import React, { useState } from 'react'
import mainLogo from '../assets/img/main-logo.svg'
import cartLogo from '../assets/img/cart-logo.svg'
import searchLogo from '../assets/img/search-logo.svg'
import redSearchLogo from '../assets/img/redSearchLogo.svg'
import menuIcon from '../assets/img/menu.svg'
import tire from '../assets/img/home-tire.svg'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'

export default function Navbar() {

    const [cartMenu, setCartMenu] = useState(false)
    const [menu, setMenu] = useState(false)
    const [search, setSearch] = useState(false)
    const navigate = useNavigate()

    const [searchParameter, setSearchParameter] = useState('')
    const searchFunc = (e) => {
        e.preventDefault()
        navigate(`/Search/${searchParameter}`)
        setSearchParameter('')
        setSearch(false)
    }

    return (
        <div className='w-screen z-10 fixed top-0 text-white '>
            <div className='min-[1920px]:w-[1920px] w-full mx-auto px-5 bg-[#0000004d] '>

                <div className='md:container  md:mx-auto xl:w-[1170px] w-full  top-0 h-28 flex justify-between items-center'>
                    <div className=''>
                        <img src={mainLogo} alt="" className='h-16' onClick={() => navigate('/')} />
                    </div>
                    <div className=''>
                        {/* <ul className='md:flex hidden  items-center'> */}
                        <ul className='flex  items-center'>
                            {
                                !search ? <>
                                    <div className='md:flex hidden items-center'>
                                        <li className='lg:ms-16 ms-7'>
                                            <NavLink
                                                to="/store"
                                                className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "border-b border-red-600" : ""
                                                }
                                            >
                                                Store
                                            </NavLink>
                                        </li>
                                        <li className='lg:ms-16 ms-7'>
                                            <NavLink to="/Brands"
                                                className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "border-b border-red-600" : ""
                                                }>
                                                Brands
                                            </NavLink>
                                        </li>
                                        <li className='lg:ms-16 ms-7'>
                                            <NavLink to="/History"
                                                className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "border-b border-red-600" : ""
                                                }>
                                                History
                                            </NavLink>
                                        </li>
                                        <li className='lg:ms-16 ms-7'>
                                            <NavLink to="/Contact"
                                                className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "border-b border-red-600" : ""
                                                }>
                                                contact
                                            </NavLink>
                                        </li>
                                    </div>
                                    <li className="lg:ms-16 ms-7 relative">
                                        <img
                                            src={cartLogo}
                                            alt="Cart"
                                            className="cursor-pointer"
                                            onClick={() => setCartMenu(true)}
                                        />
                                        {/* Badge */}
                                        <span className="absolute -bottom-2 -right-2 bg-red text-white rounded-full text-xs px-2 py-1 mb-1 mr-1">2</span>
                                    </li>
                                    <li className='md:block hidden lg:ms-16 ms-7'><img src={searchLogo} alt="" className='' onClick={() => { setSearch(!search) }} /></li>
                                    <li className='md:hidden block sm:mx-10 mx-5 z-20'><img src={menuIcon} alt="" className='h-7' onClick={() => setMenu(!menu)} /></li>
                                </> :
                                    <>
                                        <div className='flex p-2 bg-white rounded z-20'>
                                            <form onSubmit={searchFunc}>
                                                <input type="text" placeholder='Search' className='bg-white lg:w-[600px] w-[400px] text-black focus:outline-none'
                                                    onChange={(e) => setSearchParameter(e.target.value)}
                                                    value={searchParameter}
                                                />
                                            </form>
                                            <li className=''><img src={redSearchLogo} alt="" className='' onClick={() => { setSearch(!search) }} /></li>
                                        </div>

                                        <div className='absolute w-screen h-screen bg-black opacity-40 top-0 left-0 z-10 select-none'></div>
                                    </>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            {/* CARD ITEM MENU */}
            {cartMenu && (
                <div className='fixed overflow-scroll sm:p-10 top-0 left-0 h-full w-full  select-none flex justify-center z-20 bg-black bg-opacity-40 scroll-none'>

                    <div className='min-[1170px]:w-[1170px] h-max md:mx-auto md:container mx-5 md:mt-28 mt-10 flex z-10 text-black'>

                        <div className='min-[500px]:w-[500px] w-full bg-[#FFFFFF] ms-auto'>

                            {/* OPTION SUMMARY */}
                            <div className='p-5 w-full'>
                                <div className='flex items-center justify-between'>
                                    <p className='font-semibold'>Items in cart <span className='text-red'>3</span></p>
                                    <p className='text-5xl' onClick={() => { setCartMenu(false) }}>&times;</p>
                                </div>
                                <hr className='border-t' />
                                <div className='mt-5'>
                                    {
                                        Array.from({ length: 3 }).map((index) => {
                                            return (
                                                <div className='mb-5 px-2 py-3 flex shadow text-sm bg-white' key={index}>
                                                    <img src={tire} alt="" className='h-16 w-16' />
                                                    <div className='sm:flex'>
                                                        <div className='mx-3 flex flex-col justify-center font-medium '>
                                                            <p className='flex'>AMERICAN FORCE CARNAGE CONCAVE WHEELS </p>
                                                            <p className='sm:text-red-500 text-slate-400'>SIZE - R20/5*120</p>
                                                        </div>
                                                        <div className='sm:me-3 sm:mx-0 mx-3 flex flex-col justify-between '>
                                                            <p className='text-slate-400 sm:block hidden'>Price</p>
                                                            <p className='text-lg text-red font-medium'>$4,351</p>
                                                        </div>
                                                    </div>
                                                    <span className='font-medium text-3xl text-slate-300 relative sm:-top-2'>&times;</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <hr className='border-t' />
                                <div className='mt-5'>
                                    <div className='sm:flex justify-between items-center'>
                                        <button className='sm:block hidden py-3 px-16 text-lg text-white font-medium bg-red' onClick={() => { navigate('/cart') }}>CHECKOUT</button>
                                        <div className='sm:block flex justify-between items-center'>
                                            <p className='text-para'>Total</p>
                                            <p className='text-red text-2xl font-semibold'>$12,992</p>
                                        </div>
                                        <button className='sm:hidden my-4 py-3 w-full text-lg text-white font-medium bg-red' onClick={() => { navigate('/cart') }}>CHECKOUT</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            {
                menu && (
                    <div className='fixed overflow-scroll top-0 left-0 h-full w-full flex justify-center z-10 bg-black bg-opacity-40'>
                        <div className='p-5 bg-[#1C1C1C] w-full h-[350px] mt-28'>
                            <div className='flex p-2 bg-white text-black'>
                                <form onSubmit={searchFunc} className='w-full'>
                                    <input type="text" placeholder='Search' className='w-full focus:outline-none'
                                        onChange={(e) => setSearchParameter(e.target.value)}
                                        value={searchParameter}
                                    />
                                </form>
                                <img src={redSearchLogo} alt="" className='bg-white' />
                            </div>
                            <div className='mt-5 text-2xl font-medium space-y-7'>
                                <p className='border-s-2 border-red-500 ps-5'>STORE</p>
                                <p className='border-s-2 border-red-500 ps-5'>BRANDS</p>
                                <p className='border-s-2 border-red-500 ps-5'>HISTORY</p>
                                <p className='border-s-2 border-red-500 ps-5'>CONTACT</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}
