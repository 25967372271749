import React from 'react'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import home1 from '../assets/img/home-1.svg'
import home2 from '../assets/img/home-2.svg'
import home3 from '../assets/img/home-3.svg'
import home4 from '../assets/img/home-4.svg'
import downArrow from '../assets/img/downArrow.svg'
import Categories from '../shared/Categories'
import Footer1 from '../shared/Footer1'
import PopularWheels from '../shared/PopularWheels'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const navigate = useNavigate()
  return (
    <>
      <Navbar />

      <div className='w-screen'>

        <div className='min-[1920px]:w-[1920px] w-full  mx-auto sm:h-[720px] h-[500px] text-white bg-cover' style={{ backgroundImage: `url(${home1})`, backgroundRepeat: 'no-repeat' }}>
          <div className='sm:pt-48 pt-36 md:container mx-5 xl:w-[1170px] md:mx-auto h-full'>

            <div className=''>
              <h1 className='md:text-7xl text-4xl font-bold'>WHEEL AND </h1>
              <h1 className='mt-4 md:text-7xl text-4xl font-bold'>TIRE PACKAGES</h1>
              <p className='mt-10 text-red text-2xl'>Search for Your Favorite <br className='sm:hidden' />Wheel & Tire Brands.</p>
              <div className="mt-10 h-10 md:flex flex-row items-center md:space-x-10 ">
                <p className='text-para'><span className='font-bold text-white'>FREE</span> Mounting and Balancing. </p>
                <p className="relative hidden md:block text-para before:content-[''] before:absolute before:left-[-18px] before:top-0 before:h-full before:w-px before:bg-red-700">
                  <span className='font-bold text-white'>$100</span> discount off your order
                </p>
                <hr className='md:hidden block w-32 my-4 border-t border-red-600' />

                <p className="md:hidden block">$100 discount off your order</p>
              </div>
            </div>


          </div>
        </div>
        <div className='md:container xl:w-[1170px] w-full md:mx-auto sm:mt-[-150px] mt-10 md:px-0 px-5'>

          <div className=' text-xl sm:flex'>
            <button className='w-full sm:w-auto px-5 py-2 me-4 font-semibold bg-red-600 border-b-4 border-red-600 text-white'>SEARCH WHEELS BY VEHICLE</button>
            <button className='w-full sm:w-auto px-5 py-2 mt-5 sm:mt-0 font-semibold bg-black opacity-70 text-white border-b-4 border-red-600'>SEARCH WHEELS BYSIZE & BRAND</button>
          </div>

          <div className='mt-5 sm:mt-0 sm:grid grid-cols-12 gap-5 gap-y-8 p-8 justify-between bg-black text-slate-600 font-semibold'>
            <select name="" id="" className='col-span-3 p-3 w-full'>
              <option value="">Year</option>
            </select>
            <select name="" id="" className='col-span-3 p-3 w-full mt-5 sm:mt-0'>
              <option value="">Make</option>
            </select>
            <select name="" id="" className='col-span-3 p-3 w-full mt-5 sm:mt-0'>
              <option value="">Model</option>
            </select>
            <select name="" id="" className='col-span-3 p-3 w-full mt-5 sm:mt-0'>
              <option value="">Drive/Trim</option>
            </select>
            <select name="" id="" className='col-span-5 p-3 w-full mt-5 sm:mt-0'>
              <option value="">Suspension</option>
            </select>
            <select name="" id="" className='col-span-5 p-3 w-full mt-5 sm:mt-0'>
              <option value="">Trimming</option>
            </select>
            <button className='col-span-2 p-3 bg-red text-white w-full mt-5 sm:mt-0'>SHOP WHEELS</button>
          </div>

        </div>

        {/* CATEGORY */}
        <div className='  sm:mt-20 mt-20'>
          <p className='md:container xl:w-[1170px] w-full md:mx-auto px-5 md:text-6xl text-4xl text-slate-800 font-bold' onClick={()=>{navigate('/category')}}><span className='pb-3 border-b-4 border-red-600'>LOR</span>EM IPSUM</p>
          <Categories />
        </div>

        {/* DOWN ARROW  */}
        <div className='md:flex hidden justify-center py-10'>
          <img src={downArrow} alt="" />
        </div>

        {/* LIFT KITS / LEVELING KITS */}
        <div className='min-[1920px]:w-[1920px] w-full md:h-[720px] h-[400px] mx-auto px-5  mt-20 flex flex-col items-center justify-center text-center  text-white bg-cover' style={{ backgroundImage: `url(${home2})`, backgroundRepeat: 'no-repeat' }}>
          <p className='md:text-6xl text-4xl font-bold mx-5'>LIFT KITS / LEVELING KITS</p>
          <p className='mx-5 my-12 md:text-2xl text-lg text-slate-300'>Lorem ipsum dolor sit amet, scelerisque mi tellus neque turpis.</p>
          <button className='w-full sm:w-auto px-5 py-2 sm:me-4 bg-red border-b-4 border-red-600 '>SHOP ALL SUSPENSION KITS</button>
        </div>

        {/* TOP 20 MOST POPULAR WHEELS */}
        <div className='min-[1920px]:w-[1920px] w-full mx-auto px-5 h-[900px] flex flex-col items-center justify-evenly bg-black'>

          <p className='text-white sm:text-6xl text-5xl font-bold text-center'><span className='text-red'>TOP 20</span> MOST POPULAR WHEELS</p>
          <span className='border-b-2 border-slate-500  '>&emsp; &emsp; &emsp; &emsp; &emsp;</span>

          <PopularWheels/>

          <button className='w-full sm:w-auto px-10 py-2 me-4 font-semibold bg-red-600 border-b-4 border-red-600 text-white'>FULL COLLECTION</button>
        </div>

        <div className='min-[1920px]:w-[1920px] w-full md:h-[720px] h-[500px] px-5 mx-auto flex flex-col items-center justify-center text-center text-white bg-cover' style={{ backgroundImage: `url(${home3})`, backgroundRepeat: 'no-repeat' }}>
          <p className='md:text-6xl text-4xl font-bold'>CHECK OUT ALL </p>
          <p className='md:text-6xl text-4xl font-bold'>OF OUR FEATURED BRANDS</p>
          <p className='my-12 md:text-2xl text-lg text-slate-300'>Lorem ipsum dolor sit amet, scelerisque mi tellus neque turpis.</p>
          <button className='w-full sm:w-auto px-5 py-2 me-4 bg-red border-b-4 border-red-600 '>VIEW OUR BRANDS</button>
        </div>

        <div className='min-[1920px]:w-[1920px] w-full px-5 mb-20 mx-auto lg:h-[650px] h-full grid grid-cols-12 md:mt-14 mt-10'>
          <div className='sm:col-span-5 col-span-12 flex'>
            <div className='md:flex hidden w-full items-center justify-center'>
              <div className=' ms-auto md:me-10'>
                <p className='md:text-5xl text-3xl font-bold'>SHOP LOCAL <br />AND SAVE</p>
                <p className='w-80 my-10 text-slate-500 text-sm'>Here at Standout Specialties, we are driven to find you the perfect parts that compliment your truck or jeep to help you standout among the rest. Interested in some info on any of the trucks pictured on the website? Give us a call today!</p>
                <button className='px-5 py-2 me-4 bg-red border-b-4 border-red-600  text-white font-semibold'>Request a Custom Quote</button>
              </div>
            </div>
          </div>
          <div className='sm:px-10 px-5 md:col-span-7 col-span-12 md:flex items-center justify-center'>
            <p className='md:hidden block mb-10 sm:text-5xl text-3xl font-bold text-center'>SHOP LOCAL <br />AND SAVE</p>
            <img src={home4} alt="" className='' />
            <p className='md:hidden block sm:w-80 w-full my-10 text-slate-500 text-sm border'>Here at Standout Specialties, we are driven to find you the perfect parts that compliment your truck or jeep to help you standout among the rest. Interested in some info on any of the trucks pictured on the website? Give us a call today!</p>
            <button className='md:hidden block w-full px-5 py-2 me-4 bg-red-600 border-b-4 border-red-600  text-white font-semibold'>Request a Custom Quote</button>
          </div>
        </div>

      </div>
      
      <div className='sm:block hidden'>
        <Footer1 />
      </div>
      <div className='block sm:hidden'>
        <Footer />
      </div>
    </>
  )
}
