import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import navImage from '../assets/img/navImage.svg'
import Footer from '../shared/Footer'
import Categories from '../shared/Categories'


export default function Category({name}) {
    return (
        <>

            <Navbar />
            <div className='w-screen'>

                <div className='min-[1920px]:w-[1920px] w-full mx-auto h-[250px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage})`, backgroundRepeat: 'no-repeat' }}>

                    <div className='sm:pt-36 pt-36 md:container sm:mx-5 xl:w-[1170px]  md:mx-auto h-full'>
                        <div className='w-full sm:flex items-center '>
                            <h1 className='sm:text-6xl text-4xl font-bold text-center sm:text-start text-nowrap'>{name?name:'CATEGORY'}</h1>
                            <hr className='sm:ms-14 sm:mt-0 mt-14 sm:border-t-2 border-t-8 border-red-600 border w-full ' />
                        </div>
                    </div>
                </div>
                <Categories />
            </div>
            
            <div className='sm:block hidden'>
                <Footer1 />
            </div>
            <div className='block sm:hidden'>
                <Footer />
            </div>

        </>
    )
}
