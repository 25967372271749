import React from 'react'
import mainLogo from '../assets/img/main-logo.svg'
import instaLogo from '../assets/img/instaIcon.svg'
import facebookIcin from '../assets/img/facebookIcin.svg'
import footerIconGroup from '../assets/img/footerIconGroup.svg'
import { useNavigate } from 'react-router-dom'

export default function Footer1() {
    const navigate = useNavigate()
    return (
        <>
            <div className='w-screen'>

                <div className='min-[1920px]:w-[1920px]   w-full mx-auto sm:bg-[#232526] bg-zinc-900 py-10'>

                    <div className='md:container xl:w-[1170px] w-full md:mx-auto text-white sm:text-start text-center  '>

                        <div className='sm:grid sm:grid-cols-12 md:gap-2 gap-10 w-full '>

                            <div className='px-5 md:col-span-3 sm:col-span-4 sm:py-0 py-10 text-sm sm:space-y-10  sm:block flex items-center justify-center '>
                                <img src={mainLogo} alt="" className='h-16' />
                                <div>
                                    <p className='mt-3 '><span className='text-slate-500'>Monday - Friday</span> 9-6</p>
                                    <p className=' mt-2'><span className='text-slate-500'>Sat</span> 9-2</p>
                                </div>
                                <hr className="border-t-2 border-red-600 w-36 mt-2 hidden sm:block"></hr>
                            </div>

                            <div className='px-5 sm:block hidden md:col-span-2 sm:col-span-4 col-span-12 '>
                                <p className='text-red-600 font-bold'>Our Site</p>
                                <div className='text-sm'>
                                    <p className='mt-3 underline'>View my cart</p>
                                    <p className='underline mt-2'>Track my order</p>
                                    <p className='underline mt-2'>Gallery</p>
                                    <p className='underline mt-2' onClick={(()=>navigate('/VideoBlog'))} >Blog</p>
                                </div>
                            </div>

                            <div className='px-5 sm:block hidden md:col-span-2 sm:col-span-4 col-span-12 '>
                                <p className='text-red-600 font-bold'>Our Site</p>
                                <div className='text-sm'>
                                    <p className='mt-3 underline'>About us</p>
                                    <p className='underline mt-2'>Contact us</p>
                                </div>
                            </div>

                            <div className='px-5 sm:mt-0 mt-8 md:col-span-5 sm:col-span-6 '>
                                <p className='text-red-600 font-bold'>Subscribe for News and Upcoming Events</p>
                                <div className='flex justify-center mt-5 '>
                                    <input type="text" placeholder='Email' className='bg-white text-black py-2 px-3 me-3 w-full  sm:block inline-block' />
                                    <button className='sm:px-5 px-3 py-2 sm:block inline-block bg-red-600 font-bold'>SUBSCRIBE</button>
                                </div>
                                <div className='flex sm:mt-5'>
                                    <img src={instaLogo} alt="" className='me-3 h-10' />
                                    <img src={facebookIcin} alt="" className='h-10' />
                                </div>
                            </div>

                            <div className='flex  mt-8 sm:hidden justify-center '>
                                <div className='px-5'>
                                    <p className='text-red-600 font-bold'>Our Site</p>
                                    <div className='text-sm'>
                                        <p className='mt-3 underline'>View my cart</p>
                                        <p className='underline mt-2'>Track my order</p>
                                        <p className='underline mt-2'>Gallery</p>
                                        <p className='underline mt-2' onClick={(()=>navigate('/VideoBlog'))}>Blog</p>
                                    </div>
                                </div>
                                <div className='px-5 '>
                                    <p className='text-red-600 font-bold'>Our Site</p>
                                    <div className='text-sm'>
                                        <p className='mt-3 underline'>About us</p>
                                        <p className='underline mt-2'>Contact us</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr className='mt-5 border-t border-slate-600 ' />

                        <div className='flex mt-5 px-5'>
                            <span className='me-5  sm:text-slate-500 text-red-900'>4011 Oregon Pike Ephrata PA 17522</span>
                            <span className='ms-auto text-red-900'>© 2020 StandOut Specialties</span>
                            <span className='ms-auto underline text-slate-400'>Privacy Policy</span>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
