import React, { useState } from 'react'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import Navbar from '../shared/Navbar'
import navImage2 from '../assets/img/navImage2.svg'
import tire from '../assets/img/home-tire.svg'
import bookmarkLogo from '../assets/img/bookmarkLogo.svg'
import { useNavigate } from 'react-router-dom'
import PopularWheels from '../shared/PopularWheels'


export default function Checkout() {

    const [showDetail, setShowDetail] = useState(false)
    const navigate = useNavigate()

    const extraDetail = [
        {
            name: 'Brand:',
            detail: 'Hardrock'
        },
        {
            name: 'Model:',
            detail: 'Crusher'
        },
        {
            name: 'Model Other::',
            detail: 'H704'
        },
        {
            name: 'Partnumber:',
            detail: 'H704-201237144GBM'
        },
        {
            name: 'Color:',
            detail: 'Gloss Black with Milled Spoke Windows'
        },
        {
            name: 'Backspacing:',
            detail: '4.77'
        }
        , {
            name: 'Boltpattern:',
            detail: '6x135'
        },
        {
            name: 'Offset:',
            detail: '-44'
        },
        {
            name: 'Wheelwidth:',
            detail: '20'
        },
        {
            name: 'Hubbore:',
            detail: '108'
        },
        {
            name: 'Loadrating:',
            detail: '2500'
        },
        {
            name: 'Wheel Exposed Lugs:',
            detail: 'No'
        },
        {
            name: 'Wheel Material:',
            detail: 'Alloy'
        },
        {
            name: 'Wheel Structure:',
            detail: 'One Piece'
        },
        {
            name: 'Wheel Spoke Number:',
            detail: 'Hardrock'
        },
        {
            name: 'Introduced:',
            detail: '2019'
        }
    ]

    return (
        <>
            <Navbar />
            <div className='w-screen  bg-[#F3F3F3]'>

                <div className='min-[1920px]:w-[1920px] w-full mx-auto bg-white'>

                    <div className='w-full h-[200px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='flex pt-40 md:container sm:mx-5 mx-5 xl:w-[1170px]  md:mx-auto h-full '>
                            <span className='text-red block md:hidden me-2'>&lt;</span>
                            <h1 className='text-center sm:text-start'>WHEELS AND TIRES </h1>
                            <span className='text-red mx-5 md:block hidden'>&gt;</span>
                            <p className='text-sm text-para md:block hidden'>American Force Carnage Concave Wheels</p>
                        </div>
                    </div>
                    <hr className='block sm:hidden sm:border-t-2 border-t-8 border-red-500 border w-full ' />

                    <div className='py-10 w-full'>

                        <div className='min-[1170px]:w-[1170px] w-full mx-auto'>

                            <div className='md:px-0 px-5 md:container mx-auto grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 xl:gap-14 gap-10'>

                                <div className='md:p-10'>

                                    <div className='block sm:hidden'>
                                        <div className='flex'>
                                            <p className='font-semibold text-xl '>American Force Carnage Concave Wheels</p>
                                            <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                        </div>
                                        <p className='my-5 text-3xl font-semibold text-red'>$4,351</p>
                                    </div>

                                    <img src={tire} alt="" className=' h-[500px] w-full bg-white md:p-10 p-5 shadow-xl' />

                                    <div className='py-5 flex shrink-0 space-x-5 overflow-y-auto'>
                                        <img src={tire} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={tire} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={tire} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={tire} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={tire} alt="" className='border h-[80px] w-[80px]' />
                                    </div>
                                    <div className='mt-10'>
                                        <p className='mt-2 text-lg text-red font-semibold'>DESCRIPTION</p>
                                        <p className='mt-4'>4 American Force Wheels.</p>
                                        <p className='mt-5'>
                                            Interested in a wheel and tire package? Call/ text for a personalized quote!
                                            These wheels are made to order, and currently have a 3-4 week lead time.
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa euismod volutpat consequat lacus ornare nunc ultricies massa facilisi. Morbi lorem mi vel purus, et, magna.
                                        </p>
                                    </div>
                                    {
                                        showDetail && (
                                            <div className='my-10 text-sm'>

                                                {
                                                    extraDetail.map((item, index) => {
                                                        return <div className='grid grid-cols-2 mb-2'>
                                                            <p className='text-[#7C8186]'>{item.name}</p>
                                                            <p>{item.detail}</p>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        )
                                    }

                                    <div className='mt-5 flex'>
                                        <div className='flex border-b border-red-500 w-40 sm:w-32' onClick={() => { setShowDetail(!showDetail) }}>
                                            <p className=''>More info</p>
                                            <div className={`ms-1 text-red ${showDetail ? 'rotate-[270deg]' : 'rotate-90'}`}>&gt;</div>
                                        </div>
                                        <div className='border-b border-slate-300 ms-5 w-full'></div>
                                    </div>

                                </div>


                                <div className='md:pe-10'>

                                    <div className='sm:block hidden'>
                                        <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                        <p className='mt-5 font-semibold text-3xl '>American Force Carnage Concave Wheels</p>
                                        <p className='my-5 text-5xl font-semibold text-red-500'>$4,351</p>
                                    </div>

                                    <div className='md:w-60 w-full'>
                                        <button className='w-full mt-10 p-2 px-5 font-semibold text-white bg-red-500'>CONTINUE TO ADD TIRES</button>

                                        <div className='my-5'>
                                            <hr className='mb-5' />
                                            <p className='mb-2 text-slate-400'>Year</p>
                                            <select name="" className='w-full p-1 bg-gray-100 border rounded'>
                                                <option value="">Lowest Price</option>
                                            </select>
                                            <p className='mt-5 mb-2 text-slate-400'>Make</p>
                                            <select name="" className='w-full p-1 bg-gray-100 border rounded'>
                                                <option value="">Lowest Price</option>
                                            </select>
                                            <p className='mt-5 mb-2 text-slate-400'>Modal</p>
                                            <select name="" className='w-full p-1 bg-gray-100 border rounded'>
                                                <option value="">Lowest Price</option>
                                            </select>
                                            <p className='mt-5 mb-2 text-slate-400'>Bolt Pattern</p>
                                            <select name="" className='w-full p-1 bg-gray-100 border rounded'>
                                                <option value="">Lowest Price</option>
                                            </select>
                                        </div>

                                        <button className='w-full mt-3 p-2 px-9 font-semibold text-red border-2 border-red-500' onClick={() => { navigate('/CategoryItem2') }}>BUY WITHOUT TIRES</button>
                                        <div className='mt-5 ms-5 flex items-center'>
                                            <input type="checkbox" id='spare' className='h-6 w-6 bg-red border me-3' />
                                            <label htmlFor="spare">Add full size spare?</label>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


                <div className='min-[1920px]:w-[1920px] w-full mx-auto px-5 h-[600px] flex flex-col items-center justify-evenly'>
                    <p className='sm:text-4xl text-3xl font-semibold text-center text-red'>YOU MANY ALSO LIKE</p>
                    <PopularWheels/>
                </div>


                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
