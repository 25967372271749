import React,{useState} from 'react'
import greaterthanRed from '../assets/img/greaterthan-red.svg'

export default function Filters() {
    const [filter, setFilter] = useState(false)
    return (
        <>
            <div className='sm:mt-0 mt-14 px-5 py-2 flex items-center justify-center sm:bg-transparent bg-red-500' onClick={() => { setFilter(!filter) }}>
                <p className='me-3'>FILTER</p>
                <img src={greaterthanRed} alt="" className='h-4 w-4 rotate-90'/>
            </div>
            {
                filter &&
                <div className='md:absolute relative top-0 left-0 md:mt-[160px] w-full '>
                    <div className='min-[1170px]:w-[1170px] md:container md:mx-auto flex '>
                        <div className='md:w-[400px] w-full ms-auto py-5 md:px-8 px-5 bg-lightBlack text-white text-xl'>
                            <div className='flex justify-between'>
                                <p>FILTER</p>
                                <img src={greaterthanRed} alt="" className='w-5 h-5 -rotate-90' onClick={() => { setFilter(false) }} />
                            </div>
                            <div className='mt-10 text-para space-y-5'>
                                <div className='bg-white p-2'>
                                    <select name="" id="" className='w-full focus:outline-none'>
                                        <option value="" className=''>Size</option>
                                    </select>
                                </div>
                                <div className='bg-white p-2'>
                                    <select name="" id="" className='w-full focus:outline-none'>
                                        <option value="" className=''>Width</option>
                                    </select>
                                </div>
                                <div className='bg-white p-2'>
                                    <select name="" id="" className='w-full focus:outline-none'>
                                        <option value="" className=''>offset</option>
                                    </select>
                                </div>
                                <div className='bg-white p-2'>
                                    <select name="" id="" className='w-full focus:outline-none'>
                                        <option value="" className=''>Bolt Patter</option>
                                    </select>
                                </div>
                            </div>
                            <button className='w-full bg-red p-2 mt-5'>FILTER</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
