import React from 'react'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import tire from '../assets/img/home-tire.svg'
import navImage2 from '../assets/img/navImage2.svg'
import { useNavigate } from 'react-router-dom'

export default function Cart() {
    
    const navigate = useNavigate()

    return (
        <>
            <div className='w-screen h-full'>

                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto bg-[#F3F3F3]'>

                    <div className='w-full h-[200px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>

                        <div className='min-[1170px]:w-[1170px] md:container mx-5  md:mx-auto flex pt-36  h-full'>
                            <h1 className='text-center sm:text-start font-semibold text-4xl' >MY CART </h1>
                        </div>

                    </div>
                    <div className='min-[1170px]:w-[1170px] h-max md:mx-auto md:container mx-5 flex z-10 text-black'>

                        <div className='w-full ms-auto'>

                            {/* OPTION SUMMARY */}
                            <div className='p-5 w-full'>
                                <p className='font-semibold my-3'>Items in the cart <span className='text-red ms-5'>3</span></p>
                                <hr className='border-t' />
                                <div className='mt-5'>
                                    {
                                        Array.from({ length: 3 }).map((index) => {
                                            return (
                                                <div className='mb-5 px-5 py-3 flex flex-col sm:flex-row items-center shadow text-sm bg-white' key={index}>
                                                    <div className='flex'>
                                                        <img src={tire} alt="" className='ms-5 sm:h-16 h-48 sm:w-16 w-48' />
                                                        <div className='relative sm:hidden text-4xl text-slate-300 '>&#10005;</div>
                                                    </div>
                                                    <div className='sm:flex w-full'>
                                                        <div className='sm:mx-7 mx-3 h-full flex flex-col justify-evenly font-medium '>
                                                            <p className='flex'>AMERICAN FORCE CARNAGE<br /> CONCAVE WHEELS </p>
                                                            <p className='text-red sm:my-auto my-3'>SIZE - R20/5*120</p>
                                                        </div>
                                                        <div className='sm:flex ms-auto sm:me-10 items-center md:space-x-10 sm:space-x-2 space-y-2'>
                                                            <div className='h-full sm:me-3 sm:mx-0 mx-3 flex sm:flex-col flex-row sm:justify-evenly justify-between '>
                                                                <p className='text-slate-400'>Article</p>
                                                                <p className=''>EWF133221</p>
                                                            </div>
                                                            <div className='h-full sm:me-3 sm:mx-0 mx-3 flex sm:flex-col flex-row sm:justify-evenly justify-between'>
                                                                <p className='text-slate-400'>unit</p>
                                                                <p className=''>KIT 4 PCS</p>
                                                            </div>
                                                            <div className='h-full sm:me-3 sm:mx-0 mx-3 flex sm:flex-col flex-row sm:justify-evenly justify-between'>
                                                                <p className='text-slate-400'>Price</p>
                                                                <p className='text-lg text-red font-medium'>$4,351</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='sm:flex hidden items-center text-4xl text-slate-300 '>&#10005;</div>    
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='mt-5'>
                                    <div className='sm:flex my-7 justify-between items-center'>
                                        <p className='font-medium sm:text-start text-center'>Discounts, taxes, and shipping will be calculated during the checkout process </p>
                                        <div className='flex justify-between items-center mx-10 my-5'>
                                            <p className='text-para sm:me-20'>Total</p>
                                            <p className='text-red text-2xl font-semibold'>$12,992</p>
                                        </div>
                                    </div>
                                    <dir className='flex sm:justify-end justify-center mb-10 p-0'>
                                        <button className='py-3 sm:px-16 px-5 text-lg text-white font-medium bg-red'  onClick={()=>{navigate('/CheckoutInfo')}}>CONTINUE TO CHECKOUT</button>
                                    </dir>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>

            </div>
        </>
    )
}
