import React from 'react'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import navImage2 from '../assets/img/navImage2.svg'
import card1 from '../assets/img/creditCard-1.svg'
import card2 from '../assets/img/creditCard-2.svg'
import card3 from '../assets/img/creditCard-3.svg'
import card4 from '../assets/img/creditCard-4.svg'
import tire from '../assets/img/home-tire.svg'
import questionLogo from '../assets/img/questionLogo.svg'
import { useNavigate } from 'react-router-dom'

export default function CheckoutInfo() {

    const navigate = useNavigate()

    return (
        <>
            <div className='w-screen bg-[#F3F3F3]'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto bg-offwhite'>
                    <div className='w-full h-[200px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>

                        <div className='min-[1170px]:w-[1170px] md:container mx-5  md:mx-auto flex pt-36  h-full'>
                            <h1 className='sm:block hidden text-center sm:text-start font-semibold lg:text-4xl md:text-3xl text-red-500' >MY CART /</h1>
                            <p className='sm:block hidden text-center sm:text-start font-semibold lg:text-4xl md:text-3xl'>INFORMATION, SHIPPING, PAYMENT</p>
                        </div>

                    </div>

                    <div className='grid md:grid-cols-2 w-full px-0'>

                        <div className='border flex bg-white px-5 py-10 md:px-0'>
                            <div className='xl:w-[550px] lg:w-[469px] w-full lg:ms-auto md:ms-5 lg:me-9 md:me-5'>
                                {/* CONTACT INFO */}
                                <div className=''>
                                    <p className='font-medium text-2xl mb-2'>Contact information</p>
                                    <input type="text" placeholder='Email or mobile phone number' className='w-full border my-2 p-2 bg-offwhite' />
                                    <div className='flex my-3'>
                                        <input type="checkbox" className='h-5 w-5 me-3' />
                                        <p>Keep me up to date on news and exclusive offers</p>
                                    </div>
                                </div>
                                {/* CONTACT INFO */}
                                <div className='mt-10'>
                                    <p className='font-medium text-2xl mb-2'>Contact information</p>
                                    <div className='flex space-x-7'>
                                        <input type="text" placeholder='First name' className='w-full border my-2 p-2 bg-offwhite' />
                                        <input type="text" placeholder='Last name' className='w-full border my-2 p-2 bg-offwhite' />
                                    </div>
                                    <input type="text" placeholder='Address' className='w-full border my-2 p-2 bg-offwhite' />
                                    <input type="text" placeholder='Apartment, suite, etc. (optional)' className='w-full border my-2 p-2 bg-offwhite' />
                                    <input type="text" placeholder='City' className='w-full border my-2 p-2 bg-offwhite' />
                                    <div className='flex space-x-7'>
                                        <input type="text" placeholder='Country/Region' className='w-full border my-2 p-2 bg-offwhite' />
                                        <input type="text" placeholder='Postal code' className='w-full border my-2 p-2 bg-offwhite' />
                                    </div>
                                </div>
                                {/* SHIPPING METHOD */}
                                <div className='mt-10'>
                                    <p className='font-medium text-2xl my-5'>Shipping method</p>
                                    <div className='my-2 p-3 flex border border-red-500 '>
                                        <input type="checkbox" name="" id="" className='h-5 w-5 me-4 mt-1' />
                                        <div className=''>
                                            <p className='font-medium'>USPS First Class Package International </p>
                                            <p className='text-para'>7 to 21 business days</p>
                                        </div>
                                        <p className='ms-auto text-red-500 font-medium'>$13.98</p>
                                    </div>
                                    <div className='my-2 p-3 flex border'>
                                        <input type="checkbox" name="" id="" className='h-5 w-5 me-4 mt-1' />
                                        <div className=''>
                                            <p className='font-medium'>USPS Priority Mail International </p>
                                            <p className='text-para'>6 to 10 business days</p>
                                        </div>
                                        <p className='ms-auto text-red-500 font-medium'>$52.70</p>
                                    </div>
                                    <div className='my-2 p-3 flex border'>
                                        <input type="checkbox" name="" id="" className='h-5 w-5 me-4 mt-1' />
                                        <div className=''>
                                            <p className='font-medium'>DHL Express Worldwide </p>
                                            <p className='text-para'>2 to 5 business days</p>
                                        </div>
                                        <p className='ms-auto text-red-500 font-medium'>$60.84</p>
                                    </div>
                                    <div className='my-2 p-3 flex border'>
                                        <input type="checkbox" name="" id="" className='h-5 w-5 me-4 mt-1' />
                                        <div className=''>
                                            <p className='font-medium'>USPS Priority Mail Express International </p>
                                            <p className='text-para'>3 to 5 business days</p>
                                        </div>
                                        <p className='ms-auto text-red-500 font-medium'>$67.28</p>
                                    </div>
                                </div>
                                {/* PAYMENT */}
                                <div className='mt-10'>
                                    <p className='font-medium text-2xl'>Shipping method</p>
                                    <p className='my-4 text-sm text-slate-600'>All transactions are secure and encrypted.</p>
                                    <div className='p-3 border border-red-500'>
                                        <div className='flex '>
                                            <input type="checkbox" name="" id="" className='h-5 w-5' />
                                            <div className='ms-3 md:flex w-full'>
                                                <p className='font-medium'>Credit card</p>
                                                <p className='my-2 text-para block md:hidden'>7 to 21 business days</p>
                                                <div className='flex md:ms-auto ms-auto space-x-2'>
                                                    <img src={card1} alt="" className='h-7 w-12 border' />
                                                    <img src={card2} alt="" className='h-7 w-12 ' />
                                                    <img src={card3} alt="" className='h-7 w-12 ' />
                                                    <img src={card4} alt="" className='h-7 w-12 ' />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='my-5' />
                                        <div className='md:flex items-center md:border md:bg-[#F3F3F3]'>
                                            <input type="text" placeholder='Card number' className='w-full md:border-x my-2 p-2 bg-offwhite' />
                                            <div className='flex md:space-x-0 space-x-5'>
                                                <input type="text" placeholder='MM/YY' className='w-full md:border-x my-2 p-2 bg-offwhite' />
                                                <input type="text" placeholder='CVC' className='w-full md:border-x my-2 p-2 bg-offwhite' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* SHIPPING ADDRESS */}
                                <div className='mt-10'>
                                    <p className='font-medium text-2xl'>Billing address</p>
                                    <p className='my-4 text-sm text-slate-600'>Select the address that matches your card or payment method.</p>
                                    <div className='mb-3 p-4 flex items-center border border-red-500'>
                                        <input type="checkbox" className='h-5 w-5 me-2' />
                                        <p className='text-sm font-medium'>Same as shipping address</p>
                                    </div>
                                    <div className='p-4 flex items-center border border-red-500'>
                                        <input type="checkbox" className='h-5 w-5 me-2' />
                                        <p className='text-sm font-medium'>Same as shipping address</p>
                                    </div>
                                </div>
                                <div className='md:flex items-center'>
                                    <button className='md:block hidden font-semibold text-red-600 w-full text-start'>&lt; BACK</button>
                                    <button className='my-5 py-3 mt-10 font-medium bg-red-500 text-white w-full' onClick={()=>{navigate('/OrderCompleted')}}>CONTINUE TO SHIPPING</button>
                                    <button className='md:hidden  font-semibold text-red-600 w-full'>&lt; BACK</button>
                                </div>
                            </div>
                        </div>

                        <div className='border flex px-5 py-10 md:px-0'>
                            <div className='xl:w-[550px] lg:w-[469px] w-full lg:ms-9 md:mx-5 lg:me-9'>
                                <div className='md:p-5 w-full'>
                                    <p className='font-semibold my-3'>Items in the cart <span className='text-red-500 ms-5'>3</span></p>
                                    <hr className='border-t' />
                                    <div className='mt-5'>
                                        {
                                            Array.from({ length: 3 }).map((index) => {
                                                return (
                                                    <div className='mb-5 px-5 py-3 flex items-center shadow text-sm bg-white' key={index}>
                                                        <div className='flex'>
                                                            <img src={tire} alt="" className='h-16 w-16' />
                                                        </div>
                                                        <div className='sm:flex w-full'>
                                                            <div className='sm:mx-7 mx-3 h-full flex flex-col justify-evenly font-medium '>
                                                                <p className='flex text-sm'>AMERICAN FORCE CARNAGE<br /> CONCAVE WHEELS </p>
                                                                <p className='text-para sm:my-auto my-2'>SIZE - R20/5*120</p>
                                                            </div>
                                                            <div className='flex ms-auto items-center '>
                                                                <div className='h-full sm:me-3 sm:mx-0 mx-3 flex sm:flex-col flex-row sm:justify-evenly items-center'>
                                                                    <p className='text-slate-400 sm:block hidden'>Price</p>
                                                                    <p className='text-lg text-red-500 font-medium'>$4,351</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex mb-auto items-center text-2xl text-slate-300 '>&#10005;</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='mt-5'>
                                        <div className='my-7 justify-between items-center'>
                                            <div className='flex justify-between items-center my-5'>
                                                <p className='text-para sm:me-20'>SubTotal</p>
                                                <p className='text-red-500 text-2xl font-semibold'>$12,992</p>
                                            </div>
                                            <div className='flex justify-between items-center my-5'>
                                                <p className='text-para sm:me-20 flex'>Shipping <img src={questionLogo} alt="" className='ms-3'/></p>
                                                <p className='text-sm font-semibold'>Calculated at next step</p>
                                            </div>
                                            <hr />
                                            <div className='flex justify-between items-center my-5'>
                                                <p className='text-para sm:me-20'>Total</p>
                                                <p className='text-red-500 text-2xl font-semibold'>$12,992</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
