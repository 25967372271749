import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../shared/Footer'
import Footer1 from '../shared/Footer1'
import Navbar from '../shared/Navbar'
import '../style/index.css'
import navImage2 from '../assets/img/navImage2.svg'
import bookmarkLogo from '../assets/img/bookmarkLogo.svg'
import greaterthanRed from '../assets/img/greaterthan-red.svg'
import rubberTire from '../assets/img/rubberTire.svg'
import rubberTire2 from '../assets/img/rubberTire2.svg'
import rubberTire3 from '../assets/img/rubberTire3.svg'
import rubberTireHalf from '../assets/img/rubberTireHalf.svg'
import tire from '../assets/img/home-tire.svg'
import PopularWheels from '../shared/PopularWheels'

export default function CheckOutMultipleItem() {

    const [showDetail, setShowDetail] = useState(false)
    const [TotalItem, setTotalItem] = useState(false)
    const navigate = useNavigate()

    const scrollContainerRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const wheels = ['wheel1', 'wheel2', 'wheel3', 'wheel4', 'wheel5'];

    const trackActiveDiv = () => {
        if (scrollContainerRef.current) {
            const scrollLeft = scrollContainerRef.current.scrollLeft;
            const containerWidth = scrollContainerRef.current.offsetWidth;
            const newIndex = Math.round(scrollLeft / containerWidth);
            setActiveIndex(newIndex);
        }
    };
    const scrollNext = () => {
        if (scrollContainerRef.current) {
            const scrollAmount = scrollContainerRef.current.offsetWidth;
            scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };
    const scrollPrev = () => {
        if (scrollContainerRef.current) {
            const scrollAmount = scrollContainerRef.current.offsetWidth;
            scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) { // Check if container is not null
            container.addEventListener('scroll', trackActiveDiv);
            return () => {
                container.removeEventListener('scroll', trackActiveDiv);
            };
        }
    }, []);

    return (
        <>
            <Navbar />
            <div className='w-screen h-full bg-[#F3F3F3]'>

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto bg-white'>

                    <div className='w-full h-[200px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>

                        <div className='min-[1170px]:w-[1170px] md:container mx-5  md:mx-auto flex pt-40  h-full'>
                            <span className='text-red block md:hidden me-2'>&lt;</span>
                            <h1 className='text-center sm:text-start' onClick={() => { setTotalItem(true) }}>WHEELS AND TIRES </h1>
                            <span className='text-red mx-5 md:block hidden'>&gt;</span>
                            <p className='text-sm text-slate-400 md:block hidden'>American Force Carnage Concave Wheels</p>
                        </div>

                    </div>
                    <hr className='block sm:hidden sm:border-t-2 border-t-8 border-red-500 border w-full ' />

                    <div className='w-full md:h-[150px] border bg-[#F3F3F3]'>
                        <div className='min-[1170px]:w-[1170px] md:container w-full md:px-0 px-5 h-full mx-auto flex flex-col sm:flex-row items-center '>

                            <div className='sm:block flex items-center sm:my-0 my-5'>
                                <p className='text-2xl mb-1 font-semibold sm:me-0 me-5'>Your&nbsp;comlect</p>
                                <button className='p-1 px-10 bg-red text-white'>Checkout</button>
                            </div>
                            <div className='mx-3'>
                                <img src={greaterthanRed} alt="" className='sm:rotate-0 rotate-90' />
                            </div>

                            <div className='py-4 w-[350px] h-full text-sm me-5'>
                                <div className='p-2 bg-white flex items-center w-full h-full border '>
                                    <img src={tire} alt="" className='w-20 h-20' />
                                    <div className='ms-3 overflow-hidden  text-nowrap space-y-2'>
                                        <p className='font-semibold '>American Force Carnage Concave Wheels</p>
                                        <div>
                                            <span className='text-slate-400 me-4'>20x10 - 25mm</span>
                                            <span className='text-slate-400 me-4'>Black Milled</span>
                                        </div>
                                        <p className='text-red text-base font-semibold'>$4,351</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='py-10 w-full'>

                        <div className='min-[1170px]:w-[1170px] w-full mx-auto'>

                            <div className='md:px-0 px-5 md:container mx-auto grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1 xl:gap-14 gap-10'>

                                <div className='md:p-10'>

                                    <div className='block sm:hidden'>
                                        <div className='flex'>
                                            <p className='font-semibold text-xl '>American Force Carnage Concave Wheels</p>
                                            <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                        </div>
                                        <p className='my-5 text-3xl font-semibold text-red'>$4,351</p>
                                    </div>

                                    <img src={rubberTireHalf} alt="" className=' h-[500px] w-full bg-white md:p-10 p-5 shadow-xl' />

                                    <div className='py-5 flex space-x-5 overflow-y-auto'>
                                        <img src={rubberTireHalf} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={rubberTire} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={rubberTire2} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={rubberTire3} alt="" className='border h-[80px] w-[80px]' />
                                        <img src={rubberTire} alt="" className='border h-[80px] w-[80px]' />
                                    </div>
                                    <div className='mt-10'>
                                        <p className='mt-2 text-lg text-red font-semibold'>DESCRIPTION</p>
                                        <p className='mt-4'>4 American Force Wheels.</p>
                                        <p className='mt-5'>
                                            Interested in a wheel and tire package? Call/ text for a personalized quote!
                                            These wheels are made to order
                                        </p>
                                    </div>

                                </div>


                                <div className='md:pe-10'>

                                    <div className='sm:block hidden'>
                                        <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                        <p className='mt-5 font-semibold text-3xl '>American Force Carnage Concave Wheels</p>
                                        <p className='my-5 text-5xl font-semibold text-red'>$4,351</p>
                                    </div>

                                    <div className='md:w-60 w-full'>
                                        <button className='w-full mt-10 p-2 px-5 font-semibold text-white bg-red-500'>CONTINUE TO ADD TIRES</button>
                                        <button className='w-full mt-3 p-2 px-9 font-semibold text-red border-2 border-red-500' onClick={() => { navigate('/CategoryItem2') }}>BUY WITHOUT TIRES</button>
                                        <div className='mt-5 ms-5 flex items-center'>
                                            <input type="checkbox" id='spare' className='h-6 w-6 bg-red-400 border me-3' />
                                            <label htmlFor="spare">Add full size spare?</label>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


                <div className='min-[1920px]:w-[1920px] w-full mx-auto px-5 h-[600px] flex flex-col items-center justify-evenly'>
                    <p className='sm:text-4xl text-3xl font-bold text-center text-red'>YOU MANY ALSO LIKE</p>
                    <PopularWheels />
                </div>


                {/* CARD ITEM MENU */}
                {TotalItem && (
                    <div className='fixed overflow-scroll sm:p-10 top-0 left-0 h-full w-full  select-none flex justify-center z-20 bg-black bg-opacity-40 sm:px-0 px-5'>

                        <div className='min-[1170px]:w-[1170px] w-full h-max md:mx-auto md:container sm:mx-5 md:mt-28 mt-5 flex z-10 bg-[#F3F3F3]  text-black'>

                            <div className='md:grid grid-cols-3 w-full'>

                                {/* BIG SCREEN */}
                                <div className='col-span-2 sm:block hidden p-7 w-full border'>
                                    <div className='w-full flex justify-between items-center'>
                                        <p className='text-4xl font-semibold'>Wheel Accessories</p>
                                        <p className='text-slate-400'>&#10003; Added to cart</p>
                                    </div>

                                    <div className='mt-5'>

                                        <div className='p-5 flex shadow-lg bg-white '>
                                            <img src={rubberTire} alt="" className='h-40 w-40' />
                                            <div className='mx-5 flex flex-col justify-between'>
                                                <p className='font-semibold text-lg'>AMERICAN FORCE CARNAGE CONCAVE WHEELS</p>
                                                <p className='text-para text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare urna convallis mi id mauris vitae. Risus pellentesque cum consequat bibendum amet. Imperdiet ac fermentum.</p>

                                                <div className='flex justify-between text-red font-semibold'>
                                                    <p className=' text-3xl'>$4,351</p>
                                                    <button className='p-3 px-7 border border-red'>REMOVE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5 p-5 flex shadow-lg bg-white'>
                                            <img src={rubberTire} alt="" className='h-40 w-40' />
                                            <div className='mx-5 flex flex-col justify-between'>
                                                <p className='font-semibold text-lg'>AMERICAN FORCE CARNAGE CONCAVE WHEELS</p>
                                                <p className='text-para text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare urna convallis mi id mauris vitae. Risus pellentesque cum consequat bibendum amet. Imperdiet ac fermentum.</p>

                                                <div className='flex justify-between text-red font-semibold'>
                                                    <p className=' text-3xl'>$4,351</p>
                                                    <button className='p-3 px-7 border border-red'>REMOVE</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5 p-5 flex shadow-lg bg-white'>
                                            <img src={rubberTire} alt="" className='h-40 w-40' />
                                            <div className='mx-5 flex flex-col justify-between'>
                                                <p className='font-semibold text-lg'>AMERICAN FORCE CARNAGE CONCAVE WHEELS</p>
                                                <p className='text-para text-sm'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare urna convallis mi id mauris vitae. Risus pellentesque cum consequat bibendum amet. Imperdiet ac fermentum.</p>

                                                <div className='flex justify-between text-red font-semibold'>
                                                    <p className=' text-3xl'>$4,351</p>
                                                    <button className='p-3 px-7 border border-red'>REMOVE</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {/* MOBILE SCREEN */}
                                <div className='col-span-2 block sm:hidden sm:p-7 p-3 w-full'>

                                    <div className='w-full flex justify-between items-center'>
                                        <p className='text-2xl font-medium'>Wheel Accessories</p>
                                        <p className='text-4xl' onClick={() => { setTotalItem(false) }}>&times;</p>
                                    </div>

                                    <div className='bg-white w-full'>
                                        <div ref={scrollContainerRef} className='mt-5 flex shrink-0 w-full overflow-x-auto bg-white '>
                                            {
                                                Array.from({ length: 5 }).map((_, index) => {
                                                    return <div className='flex-shrink-0 mx-auto sm:p-5 shadow-lg p-5 w-full' key={index}>
                                                        <div className='flex justify-evenly'>
                                                            <img src={greaterthanRed} alt="" className="rotate-180 cursor-pointer" onClick={scrollPrev} />
                                                            <img src={rubberTire} alt="" className='h-40 w-40' />
                                                            <img src={greaterthanRed} alt="" className="cursor-pointer" onClick={scrollNext} />
                                                        </div>
                                                        <div className='mx-5 flex flex-col justify-between'>
                                                            <p className='font-semibold mt-5'>AMERICAN FORCE CARNAGE CONCAVE WHEELS</p>
                                                            <p className='my-2 text-2xl text-red font-semibold'>$4,351</p>
                                                            <div className='mt-2 flex justify-between text-red font-semibold'>
                                                                <p className='p-2 px-2 bg-red text-white'>ADD TO CARD</p>
                                                                <button className='p-2 px-2 border border-red-500 '>REMOVE</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>


                                    </div>
                                    <div className="flex justify-center mt-4">
                                        {wheels.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`h-3 w-3  mx-2 ${index === activeIndex ? 'bg-red' : 'bg-gray-300'}`}
                                            />
                                        ))}
                                    </div>

                                </div>

                                {/* OPTION SUMMARY */}
                                <div className='col-span-1 p-7 w-full'>
                                    <div className='flex sm:justify-between justify-center items-center'>
                                        <p className='text-2xl font-semibold'>Option summary</p>
                                        <p className='text-5xl sm:block hidden' onClick={() => { setTotalItem(false) }}>&times;</p>
                                    </div>

                                    <div className='mt-5'>

                                        <div className='p-1 py-2 flex shadow-lg  text-sm bg-white'>
                                            <img src={rubberTire} alt="" className='h-16 w-16' />
                                            <div className='mx-3 flex flex-col justify-center'>
                                                <p className='font-semibold flex'>AMERICAN FORCE CARNAGE CONCAVE WHEELS <span className='font-medium text-3xl text-slate-300 relative sm:-top-2'>&times;</span></p>
                                                <p className='text-red font-semibold'>$4,351</p>
                                            </div>
                                        </div>
                                        <div className='mt-5 p-1 py-2 flex shadow-lg  text-sm bg-white'>
                                            <img src={rubberTire} alt="" className='h-16 w-16' />
                                            <div className='mx-3 flex flex-col justify-between'>
                                                <p className='font-semibold flex'>AMERICAN FORCE CARNAGE CONCAVE WHEELS <span className='font-medium text-4xl text-slate-300 relative -top-2'>&times;</span></p>
                                                <p className='text-red font-semibold'>$4,351</p>
                                            </div>
                                        </div>
                                        <div className='mt-5 p-1 py-2 flex shadow-lg  text-sm bg-white'>
                                            <img src={rubberTire} alt="" className='h-16 w-16' />
                                            <div className='mx-3 flex flex-col justify-between'>
                                                <p className='font-semibold flex'>AMERICAN FORCE CARNAGE CONCAVE WHEELS <span className='font-medium text-4xl text-slate-300 relative -top-2'>&times;</span></p>
                                                <p className='text-red font-semibold'>$4,351</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='mt-5'>
                                        <div className='flex justify-between items-center'>
                                            <p className='text-para'>Subtotal</p>
                                            <p className='text-red text-xl font-semibold'>$12,992</p>
                                        </div>
                                        <button className='mt-5 py-3 w-full text-lg text-red font-semibold border-2 border-red-500'>CONTINUE SHOPPING</button>
                                        <button className='mt-5 py-3 w-full text-lg text-red font-semibold border-2 border-red-500'>ADD SUSPENSION</button>
                                        <button className='mt-5 py-3 w-full text-lg text-white font-semibold bg-red'>Proceed to cart</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
