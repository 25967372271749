import React, { useState } from 'react'
import Navbar from '../shared/Navbar'
import Categories from '../shared/Categories'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage2 from '../assets/img/navImage2.svg'
import redGreaterthan from '../assets/img/greaterthan-red.svg'
import darkSearchLogo from '../assets/img/dark-search-logo.svg'

import tire from '../assets/img/home-tire.svg'
import bookmarkLogo from '../assets/img/bookmarkLogo.svg'
import rightNevigate from '../assets/img/rightNevigate.svg'
import cartLogo from '../assets/img/addToCart-logo.svg'
import greaterthanRed from '../assets/img/greaterthan-red.svg'
import { useNavigate, useParams } from 'react-router-dom'

export default function Search() {

    const navigate = useNavigate()
    const [filter, setFilter] = useState(false)
    const { searchParameter } = useParams()
    console.log(searchParameter)

    return (
        <>
            <div className='w-screen'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto bg-offwhite'>
                    {
                        searchParameter === undefined ?
                            <>
                                <div className='w-full md:h-[230px] h-[250px] text-white bg-cover flex' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                                    <div className='min-[1170px]:w-[1170px] md:container mt-auto mb-7 mx-5 md:flex items-center md:mx-auto'>
                                        <h1 className='font-bold sm:text-4xl text-3xl' >RESULTS ON PAGE</h1>
                                        <div className='ms-auto md:mt-auto mt-5 flex'>
                                            <div className='flex items-center'>
                                                <p className='me-5 text-para md:block hidden'>Sort by</p>
                                                <select name="" id="" className='py-1 sm:pe-20 pe-12 ps-3 bg-white text-black'>
                                                    <option value="">Lowest Prize</option>
                                                </select>
                                            </div>
                                            <div className='flex items-center ' onClick={() => { setFilter(!filter) }}>
                                                <p className='sm:ms-16 ms-5 me-2'>FILTER</p>
                                                <img src={redGreaterthan} alt="" className={`h-6 w-6 ${filter ? 'rotate-90' : '-rotate-90'}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filter &&
                                    <div className='md:absolute relative top-0 left-0 md:mt-[160px] w-full '>
                                        <div className='min-[1170px]:w-[1170px] md:container md:mx-auto flex '>
                                            <div className='md:w-[400px] w-full ms-auto py-5 md:px-8 px-5 bg-lightBlack text-white text-xl'>
                                                <div className='flex justify-between'>
                                                    <p>FILTER</p>
                                                    <img src={redGreaterthan} alt="" className='w-5 h-5 -rotate-90' onClick={() => { setFilter(false) }} />
                                                </div>
                                                <div className='mt-10 text-para space-y-5'>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Size</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Width</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>offset</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Bolt Patter</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button className='w-full bg-red p-2 mt-5'>FILTER</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className=''>
                                    <div className='min-[1170px]:w-[1170px] md:container md:mx-auto sm:mx-0 mx-5'>
                                        <div className='flex items-center my-24 space-x-5'>
                                            <img src={darkSearchLogo} alt="" />
                                            <p className='text-para'>No results were found for your search.</p>
                                        </div>
                                        <p className='text-4xl my-5 font-bold'>TRY SEARCHING THROUGH THE DIRECTORY</p>
                                        <hr className='w-24 border border-red-600' />
                                    </div>
                                    <Categories />
                                </div>
                            </>
                            :
                            <>
                                <div className='w-full h-[250px] text-white bg-cover' style={{ backgroundImage: `url(${navImage2})`, backgroundRepeat: 'no-repeat' }}>
                                    <div className='sm:pt-40 pt-40 min-[1170px]:w-[1170px] w-full h-full md:container md:mx-auto'>
                                        <div className='px-5 w-full flex flex-col sm:flex-row items-center justify-between'>
                                            <h1 className='sm:text-5xl text-3xl font-bold text-center sm:text-start'>RESULTS ON PAGE</h1>
                                            
                                            <div className='sm:mt-0 mt-14 px-5 py-2 flex items-center justify-center sm:bg-transparent bg-red-500' onClick={() => { setFilter(!filter) }}>
                                                <p className='me-2'>FILTER</p>
                                                <p className='font-bold text-2xl rotate-90'>&gt;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='block sm:hidden sm:border-t-2 border-t-8 border-red-500 border w-full ' />

                                <div className='w-full h-full bg-white border block sm:hidden'>
                                    <div className='mt-10 sm:mt-0 px-5 min-[1170px]:w-[1170px] w-full mx-auto sm:h-16 h-40 text-black bg-white'>
                                        <div className='py-5 sm:py-0 md:container mx-auto sm:grid grid-cols-3 md:gap-14 gap-10 h-full sm:space-y-0 space-y-3'>

                                            <div className='flex items-center sm:justify-start justify-center'>
                                                <p className='me-2 font-medium'>Total search results for "Wheel"</p>
                                                <p className='text-red-500 font-medium'>129</p>
                                                <div className="sm:block hidden h-10 w-px bg-gray-400 ms-10"></div>
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='sm:ms-auto me-auto sm:me-5 text-para'>Resoults on page</p>
                                                <select name="" id="" className='px-3 py-1 border'>
                                                    <option value="">30</option>
                                                </select>
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='sm:ms-auto me-auto sm:me-5 text-para'>Sort by</p>
                                                <select name="" id="" className='px-3 py-1 border'>
                                                    <option value="">Lowest Prize</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filter &&
                                    <div className='md:absolute relative top-0 left-0 md:mt-[160px] w-full '>
                                        <div className='min-[1170px]:w-[1170px] md:container md:mx-auto flex '>
                                            <div className='md:w-[400px] w-full ms-auto py-5 md:px-8 px-5 bg-lightBlack text-white text-xl'>
                                                <div className='flex justify-between'>
                                                    <p>FILTER</p>
                                                    <img src={redGreaterthan} alt="" className='w-5 h-5 -rotate-90' onClick={() => { setFilter(false) }} />
                                                </div>
                                                <div className='mt-10 text-para space-y-5'>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Size</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Width</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>offset</option>
                                                        </select>
                                                    </div>
                                                    <div className='bg-white p-2'>
                                                        <select name="" id="" className='w-full focus:outline-none'>
                                                            <option value="" className=''>Bolt Patter</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button className='w-full bg-red p-2 mt-5'>FILTER</button>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='py-10 w-full '>
                                    <div className='min-[1170px]:w-[1170px] w-full mx-auto border-green-600 '>

                                        <div className='px-5 md:container mx-auto grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-14 gap-10'>

                                            {
                                                Array.from({ length: 9 }).map((index) => {
                                                    return (
                                                        <div className='py-3 lg:px-8 px-5 w-full flex flex-col shadow-2xl bg-white' key={index}>
                                                            <img src={bookmarkLogo} alt="" className='ms-auto h-7' />
                                                            <div className='flex justify-center'>
                                                                <img src={tire} alt="" className='' />
                                                            </div>
                                                            <p className='mt-5'>American Force Carnage Concave Wheels</p>
                                                            <div className='mt-2 flex items-center'>
                                                                <p className='text-xl font-semibold text-red-500'>$4,351</p>
                                                                <button className='ms-auto p-2 me-2 border-2 border-red-600' onClick={() => { navigate('/CheckOutMultipleItem') }}><img src={rightNevigate} alt="" className='md:h-6 h-4 md:w-6 w-4' /></button>
                                                                <img src={cartLogo} alt="" className='h-11 w-11' />
                                                            </div>
                                                            <div className='mt-5'>
                                                                <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Product SKU</p><p className='font-semibold'>TIS 544BM</p></div>
                                                                <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Wheel dimension</p><p className='font-semibold'>20x10 - 25mm</p></div>
                                                                <div className='flex justify-between my-2'><p className='text-[#7C8186]'>Color</p><p className='font-semibold'>Black Milled</p></div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        <hr className='border mt-10 mb-5' />
                                        <div className='flex justify-end items-center space-x-5'>
                                            <p>page</p>
                                            <button><img src={greaterthanRed} alt="" className='rotate-180' /></button>
                                            <button className='h-full w-7 hover:bg-white'>1</button>
                                            <button className='h-full w-7 hover:bg-white'>2</button>
                                            <button className='h-full w-7 hover:bg-white'>3</button>
                                            <button className='text-3xl'><img src={greaterthanRed} alt="" /></button>
                                        </div>

                                    </div>

                                </div>
                            </>

                    }

                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
