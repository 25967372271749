import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage3 from '../assets/img/navImage3.svg'
import home4 from '../assets/img/home-4.svg'

export default function History() {
    return (
        <>
            <div className='w-screen'>
                <Navbar />


                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='w-full md:h-[300px] h-[400px] flex text-white bg-cover' style={{ backgroundImage: `url(${navImage3})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='mt-auto md:container sm:mx-5 px-5 md:px-0 xl:w-[1170px] md:mx-auto'>
                            <div className='w-full sm:md-0 md:mb-10 mb-5 md:flex items-center'>
                                <div>
                                    <h1 className='md:text-6xl text-4xl font-bold' >HISTORY</h1>
                                    <hr className='mt-5 border-t-2 border-red-500 w-32' />
                                </div>
                                <p className='md:ms-16 mt-5 lg:text-[20px] text-sm text-para md:leading-6'>Founded in December of 2019, Standout Specialties was always a dream to create a "one stop shop" for all truck and jeep accessory needs. We wanted to be more dynamic and offer more then your typical online retail store. Standout set out to find brands, of every size, and offer their products on the site.</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-2 w-full px-0 py-20'>

                        <div className='flex px-5 py-10 md:px-0'>
                            <div className='xl:w-[550px] lg:w-[469px] w-full lg:ms-auto md:ms-5 lg:me-9 md:me-5 space-y-10'>
                                <p className='text-[48px] font-bold'>LOREM IPSUM</p>
                                <p className='text-xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum a sed dictumst sed purus euismod amet at velit. Ut scelerisque dignissim platea viverra neque. Dui, at nec dui vulputate a elit. Cras consectetur feugiat nulla aliquet pulvinar. Maecenas et ultricies amet vitae sit. Dui elit sem ac integer in. Quis eget pretium proin odio nullam. Cursus volutpat pulvinar vel fermentum. Dignissim aliquam cursus enim, faucibus.
                                Sed laoreet aliquam bibendum iaculis. Integer sed non cras id.</p>
                                <div className='flex md:justify-start justify-center'>
                                <button className='px-14 py-3 bg-red text-white font-semibold'>BEST SELECTION</button>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center'>
                            <img src={home4} alt="" />
                        </div>
                    </div>
                </div>


                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
