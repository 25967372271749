import Routing from "./route/Routing";

function App() {
  return (
    <>
      <Routing/>
    </>
  );
}

export default App;
