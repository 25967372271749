import React from 'react'
import darkSearchLogo from '../assets/img/dark-search-logo.svg'
import redGreaterthan from '../assets/img/greaterthan-red.svg'

export default function FilterOptions({setFilter}) {
    return (
        <div className='md:absolute relative top-0 left-0 md:mt-[160px] w-full '>
            <div className='min-[1170px]:w-[1170px] md:container md:mx-auto flex '>
                <div className='md:w-[400px] w-full ms-auto py-5 md:px-8 px-5 bg-lightBlack text-white text-xl'>
                    <div className='flex justify-between'>
                        <p>FILTER</p>
                        <img src={redGreaterthan} alt="" className='w-5 h-5 -rotate-90' onClick={() => { setFilter(false) }} />
                    </div>
                    <div className='mt-10 text-para space-y-5'>
                        <div className='bg-white p-2'>
                            <select name="" id="" className='w-full focus:outline-none'>
                                <option value="" className=''>Size</option>
                            </select>
                        </div>
                        <div className='bg-white p-2'>
                            <select name="" id="" className='w-full focus:outline-none'>
                                <option value="" className=''>Width</option>
                            </select>
                        </div>
                        <div className='bg-white p-2'>
                            <select name="" id="" className='w-full focus:outline-none'>
                                <option value="" className=''>offset</option>
                            </select>
                        </div>
                        <div className='bg-white p-2'>
                            <select name="" id="" className='w-full focus:outline-none'>
                                <option value="" className=''>Bolt Patter</option>
                            </select>
                        </div>
                    </div>
                    <button className='w-full bg-red p-2 mt-5'>FILTER</button>
                </div>
            </div>
        </div>
    )
}
