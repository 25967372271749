import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from '../presentation/Home'
import Category from '../presentation/Category'
import Store from '../presentation/Store'
import CategoryItems from '../presentation/CategoryItems'
import CategoryItems2 from '../presentation/categoryItems2'
import Checkout from '../presentation/Checkout'
import CheckOutMultipleItem from '../presentation/CheckOutMultipleItem'
import Cart from '../presentation/Cart'
import CheckoutInfo from '../presentation/CheckoutInfo'
import OrderCompleted from '../presentation/OrderCompleted'
import RequestProduct from '../presentation/RequestProduct'
import Search from '../presentation/Search'
import SearchByVehicle from '../presentation/SearchByVehicle'
import Brand from '../presentation/Brand'
import BrandItems from '../presentation/BrandItems'
import ShopByCategory from '../presentation/ShopByCategory'
import VideoBlog from '../presentation/VideoBlog'
import Contact from '../presentation/Contact'
import History from '../presentation/History'
import PageNotFound from '../presentation/PageNotFound'

export default function Routing() {

    const routingArray = createBrowserRouter([
        {
            path: '/',
            element: <Home />
        },
        {
            path:'/category',
            element:<Category/>
        },
        {
            path:'/Store',
            element:<Store/>
        },
        {
            path:'/CategoryItem',
            element:<CategoryItems/>
        },
        {
            path:'/CategoryItem2',
            element:<CategoryItems2/>
        },
        {
            path:'/Checkout',
            element:<Checkout/>
        },
        {
            path:'/CheckOutMultipleItem',
            element:<CheckOutMultipleItem/>
        },
        {
            path:'/Cart',
            element:<Cart/>
        },
        {
            path:'/CheckoutInfo',
            element:<CheckoutInfo/>
        },
        {
            path:'/OrderCompleted',
            element:<OrderCompleted/>
        },
        {
            path:'/RequestProduct',
            element:<RequestProduct/>
        },
        {
            path:'/Search/:searchParameter',
            element:<Search/>
        },
        {
            path:'/Search/',
            element:<Search/>
        },
        {
            path:'/SearchByVehicle',
            element:<SearchByVehicle/>
        },
        {
            path:'/Brands',
            element:<Brand/>
        },
        {
            path:'/BrandItems',
            element:<BrandItems/>
        },
        {
            path:'/ShopByCategory',
            element:<ShopByCategory/>
        },
        {
            path:'/VideoBlog',
            element:<VideoBlog/>
        },
        {
            path:'/Contact',
            element:<Contact/>
        },
        {
            path:'/History',
            element:<History/>
        },
        {
            path:'/PageNotFound',
            element:<PageNotFound/>
        }
    ])

    return (
        <>
            <RouterProvider router={routingArray}/>
        </>
    )
}
