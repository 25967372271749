import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import PageNotFoundImg from '../assets/img/PageNotFound.svg'
import { useNavigate } from 'react-router-dom'

export default function PageNotFound() {
    const navigate = useNavigate()
    return (
        <>
            <div className='w-screen'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='w-full h-[400px] flex text-white bg-black'></div>

                    <div className='-mt-64 mb-40 px-5 min-[1170px]:w-[1170px] md:container md:mx-auto w-full'>
                        <div className='w-full flex flex-col items-center space-y-5'>
                            <p className='text-white text-[64px] font-bold'>Opps!</p>
                            <img src={PageNotFoundImg} alt="" className='lg:h-[500px] sm:h-[400px] h-[200px]' />
                            <p className='text-center text-lg text-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum sagittis, elementum lectus turpis dui<br className='lg:block hidden'/> fringilla sed commodo eleifend. Fermentum pulvinar turpis magna rutrum non.</p>
                            <div className='flex justify-center'>
                            <button className='px-14 py-3 bg-red font-bold text-white' onClick={()=>navigate('/')}>HOME</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
