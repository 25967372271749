import React, { useState } from 'react'
import tire1 from '../assets/img/tires1.svg'
import tire2 from '../assets/img/tire2.svg'
import tire3 from '../assets/img/tire3.svg'
import tire4 from '../assets/img/tire4.svg'
import tire5 from '../assets/img/tire5.svg'
import greaterthan from '../assets/img/greaterthan-red.svg'
import { useNavigate } from 'react-router-dom'

export default function Categories() {

    const navigate = useNavigate()


    const [activeMenu, setActiveMenu] = useState(null);

    const handleToggle = (section) => {
        setActiveMenu(activeMenu === section ? null : section); // Toggle the menu
    };

    const menu = () => {
        return (
            <div className='w-screen sm:absolute relative left-0 pe-10 sm:pe-0'>
                <div className="min-[1920px]:w-[1920px] w-full mx-auto  sm:pe-0 sm:px-5 text-[#7C8186] flex justify-center">
                    <div className='min-[1350px]:w-[1350px] w-full h-full sm:mx-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-10 sm:p-10 p-5 bg-[#1C1C1C]'>
                        <div className=' text-sm space-y-1'>
                            <p className='text-white pb-2 font-semibold text-xl'>EXTERIOR</p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Back Racks and Tool Boxes</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Bed Covers and Tonneau Covers</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Bumpers and Winches</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Visors and Rain Guards</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Diff Covers</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Fender Flares</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Hard Tops</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Hitches and Towing</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Rock Sliders</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Running Boards and Side Steps</span></p>
                            <p className=''><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Spare Tire Carriers</span></p>
                        </div>
                        <div className=' text-sm space-y-1'>
                            <p className='text-white pb-2 font-semibold text-xl'>LIGHTING</p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Headlight Bulbs</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>LED Lighting</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Rock Lights and Wheel Lights</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Light Bars</span></p>
                            <p className='text-white  pt-5 pb-2 font-semibold text-xl'>INTERIOR PARTS</p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Floor Mats</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Gauges and Monitoring</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Interior Parts/ Accessories</span></p>
                        </div>
                        <div className=' text-sm space-y-1'>
                            <p className='text-white pb-2 font-semibold text-xl'>SUSPENSION</p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>3 Links/ 4 Links/ Radius Arms and Mounts</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Body Lifts</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Brakes and Rotors</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Lift Kits</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Leveling Kits</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Shocks</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Steering Boxes and Pitman Arms</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Steering Stabilizers</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Traction Bars</span></p>
                        </div>
                        <div className=' text-sm space-y-1'>
                            <div className='flex'>
                                <p className='text-white pb- font-semibold text-xl'>PERFORMANCE</p>
                                <div className='ms-auto text-red-600 relative -top-5 left-5 sm:block hidden' onClick={() => { setActiveMenu(null) }}><img src={greaterthan} alt="" className='h-10 -rotate-90 w-10 fill-red-600' /></div>
                                {/* <div className='relative ms-auto border text-red-600 text-2xl rotate-90 w-10 '>&lt;</div> */}
                            </div>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Air Intakes</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Chips, Tuners, & Programmers</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Radiator and Inter-cooling</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Exhaust Kits and Tips</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Fuel Systems and Components</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Transmissions</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Turbos and Turbo Parts</span></p>
                            <p className='text-white  pt-5 pb-2 font-semibold text-xl'>WHEELS AND TIRES</p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Wheels</span></p>
                            <p><span className='hover:border-b hover:border-red-600 border-b border-[#1C1C1C]'>Tires</span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <div className='min-[1920px]:w-[1920px] w-full mx-auto'>
            <div className='md:container xl:w-[1170px] w-full md:mx-auto sm:px-0 px-5 select-none'>

                <div className='my-20 grid grid-cols-12 gap-5 text-white mx-auto md:text-4xl text-2xl'>

                    {/* Wheels and Tires */}
                    <div className='sm:col-span-6 col-span-12  sm:h-[370px] h-[250px]  bg-cover' style={{ backgroundImage: `url(${tire1})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='h-full w-full  bg-[#0000004d] flex flex-col items-center justify-center sm:border-b-0 border-b-4 border-red-600'>
                            <p className='h-full w-full' onClick={() => { navigate('/CategoryItem') }}></p>
                            <p onClick={() => handleToggle("wheelsTires")}
                                className={`sm:text-start text-center sm:px-10 py-5 sm:pb-10 mt-16 sm:mt-auto sm:m-0 font-bold w-full sm:border-b-4 ${activeMenu === "wheelsTires" && 'sm:border-b-4 border-red-600'}`}>
                                Wheels and Tires
                            </p>
                            <p className={`text-7xl block sm:hidden text-red-700 ${activeMenu === 'wheelsTires' ? 'rotate-90' : '-rotate-90'}`} onClick={() => { { activeMenu === null ? setActiveMenu('wheelsTires') : setActiveMenu(null) } }}>&lt;</p>
                        </div>
                        <div className='sm:block hidden'>
                            {activeMenu === "wheelsTires" && (menu())}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        {activeMenu === "wheelsTires" && (menu())}
                    </div>

                    {/* SUSPENSION */}
                    <div className='sm:col-span-6 col-span-12  sm:h-[370px] h-[250px]  bg-cover' style={{ backgroundImage: `url(${tire2})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='h-full w-full  bg-[#0000004d] flex flex-col items-center justify-center sm:border-b-0 border-b-4 border-red-600'>
                            <p className='h-full w-full' onClick={() => { navigate('/CategoryItem') }}></p>
                            <p onClick={() => handleToggle("Suspension")}
                                className={`sm:text-start text-center sm:px-10 py-5 sm:pb-10 mt-16 sm:mt-auto sm:m-0 font-bold w-full sm:border-b-4 ${activeMenu === "Suspension" && 'sm:border-b-4 border-red-600'}`}>
                                Suspension
                            </p>
                            <p className={`text-7xl block sm:hidden text-red-700 ${activeMenu === 'Suspension' ? 'rotate-90' : '-rotate-90'}`} onClick={() => { { activeMenu === null ? setActiveMenu('Suspension') : setActiveMenu(null) } }}>&lt;</p>
                        </div>
                        <div className='sm:block hidden'>
                            {activeMenu === "Suspension" && (menu())}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        {activeMenu === "Suspension" && (menu())}
                    </div>

                    {/* Wheel Accessories */}
                    <div className='lg:col-span-4 sm:col-span-6 col-span-12  sm:h-[370px] h-[250px]  bg-cover' style={{ backgroundImage: `url(${tire3})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='h-full w-full  bg-[#0000004d] flex flex-col items-center justify-center sm:border-b-0 border-b-4 border-red-600'>
                            <p className='h-full w-full' onClick={() => { navigate('/CategoryItem') }}></p>
                            <p onClick={() => handleToggle("WheelAccessories")}
                                className={`sm:text-start text-center sm:px-10 py-5 sm:pb-10 mt-16 sm:mt-auto sm:m-0 font-bold w-full sm:border-b-4 ${activeMenu === "WheelAccessories" && 'sm:border-b-4 border-red-600'}`}>
                                Wheel Accessories
                            </p>
                            <p className={`text-7xl block sm:hidden text-red-700 ${activeMenu === 'WheelAccessories' ? 'rotate-90' : '-rotate-90'}`} onClick={() => { { activeMenu === null ? setActiveMenu('WheelAccessories') : setActiveMenu(null) } }}>&lt;</p>
                        </div>
                        <div className='sm:block hidden'>
                            {activeMenu === "WheelAccessories" && (menu())}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        {activeMenu === "WheelAccessories" && (menu())}
                    </div>

                    {/* Lighting */}
                    <div className='lg:col-span-4 sm:col-span-6 col-span-12  sm:h-[370px] h-[250px]  bg-cover' style={{ backgroundImage: `url(${tire4})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='h-full w-full  bg-[#0000004d] flex flex-col items-center justify-center sm:border-b-0 border-b-4 border-red-600'>
                            <p className='h-full w-full' onClick={() => { navigate('/CategoryItem') }}></p>
                            <p onClick={() => handleToggle("LEDLighting")}
                                className={`sm:text-start text-center sm:px-10 py-5 sm:pb-10 mt-16 sm:mt-auto sm:m-0 font-bold w-full sm:border-b-4 ${activeMenu === "LEDLighting" && 'sm:border-b-4 border-red-600'}`}>
                                Lighting
                            </p>
                            <p className={`text-7xl block sm:hidden text-red-700 ${activeMenu === 'LEDLighting' ? 'rotate-90' : '-rotate-90'}`} onClick={() => { { activeMenu === null ? setActiveMenu('LEDLighting') : setActiveMenu(null) } }}>&lt;</p>
                        </div>
                        <div className='sm:block hidden'>
                            {activeMenu === "LEDLighting" && (menu())}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        {activeMenu === "LEDLighting" && (menu())}
                    </div>

                    {/* Apparel */}
                    <div className='lg:col-span-4 sm:col-span-6 col-span-12  sm:h-[370px] h-[250px]  bg-cover' style={{ backgroundImage: `url(${tire5})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='h-full w-full  bg-[#0000004d] flex flex-col items-center justify-center sm:border-b-0 border-b-4 border-red-600'>
                            <p className='h-full w-full' onClick={() => { navigate('/CategoryItem') }}></p>
                            <p onClick={() => handleToggle("Apparel")}
                                className={`sm:text-start text-center sm:px-10 py-5 sm:pb-10 mt-16 sm:mt-auto sm:m-0 font-bold w-full sm:border-b-4 ${activeMenu === "Apparel" && 'sm:border-b-4 border-red-600'}`}>
                                Apparel
                            </p>
                            <p className={`text-7xl block sm:hidden text-red-700 ${activeMenu === 'Apparel' ? 'rotate-90' : '-rotate-90'}`} onClick={() => { { activeMenu === null ? setActiveMenu('Apparel') : setActiveMenu(null) } }}>&lt;</p>
                        </div>
                        <div className='sm:block hidden'>
                            {activeMenu === "Apparel" && (menu())}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        {activeMenu === "Apparel" && (menu())}
                    </div>

                </div>

            </div>
        </div>
    )
}
