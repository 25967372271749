import React from 'react'
import Navbar from '../shared/Navbar'
import Footer1 from '../shared/Footer1'
import Footer from '../shared/Footer'
import navImage from '../assets/img/navImage.svg'
import rightNevigate from '../assets/img/rightNevigate.svg'
import brand1 from '../assets/img/brand1.svg'
import brand2 from '../assets/img/brand2.svg'
import brand3 from '../assets/img/brand3.svg'
import brand4 from '../assets/img/brand4.svg'
import brand5 from '../assets/img/brand5.svg'
import brand6 from '../assets/img/brand6.svg'
import { useNavigate } from 'react-router-dom'

export default function Brand() {

    const navigate = useNavigate();

    const brands = (name) => {
        return (
            <div className='mt-10 md:px-0 px-5'>
                <div className='flex items-center lg:px-12 px-5'>
                    <p className='md:text-4xl text-2xl md:font-bold font-semibold text-center md:w-auto w-full text-nowrap'>{name}</p>
                    <hr className='md:block hidden w-full lg:mx-10 mx-5 border-t-2 border-slate-300 ' />
                    <div className='md:flex hidden py-3 w-80 justify-center items-center text-red font-bold text-nowrap border-2 border-red-500'>
                        <p className='text-sm'>SHOW MORE</p>
                        <img src={rightNevigate} alt="" className='ms-3' />
                    </div>
                </div>
                <div className="grid sm:grid-cols-3 grid-cols-2 gap-5 justify-items-center p-5">
                    <img src={brand1} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                    <img src={brand2} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                    <img src={brand3} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                    <img src={brand4} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                    <img src={brand5} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                    <img src={brand6} alt="" className="sm:h-[220px] h-[98px] w-[360px] object-cover" onClick={()=>navigate('/BrandItems')}/>
                </div>
                <div className='flex justify-center'>
                    <div className='flex md:hidden py-3 w-40 justify-center items-center text-red font-bold text-nowrap border-2 border-red-500'>
                        <p className='text-sm'>SHOW MORE</p>
                        <img src={rightNevigate} alt="" className='ms-3' />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='w-full bg-offwhite'>
                <Navbar />

                <div className='min-[1920px]:w-[1920px] w-full h-full mx-auto'>

                    <div className='w-full h-[250px] text-white bg-cover ' style={{ backgroundImage: `url(${navImage})`, backgroundRepeat: 'no-repeat' }}>
                        <div className='sm:pt-36 pt-36 md:container sm:mx-5 xl:w-[1170px]  md:mx-auto h-full'>
                            <div className='w-full sm:flex items-center '>
                                <h1 className='sm:text-6xl text-4xl font-bold text-center sm:text-start text-nowrap'>SHOP BY BRAND</h1>
                                <hr className='sm:ms-14 sm:mt-0 mt-14 sm:border-t-2 border-t-8 border-red-600 border w-full ' />
                            </div>
                        </div>
                    </div>

                    <div className='min-[1170px]:w-[1170px] w-full md:container md:mx-auto my-14  '>

                        {brands('WHEEL BRANDS')}
                        {brands('SUSPENSION BRANDS')}
                        {brands('WHEEL BRANDS')}

                    </div>

                </div>

                <div className='sm:block hidden'>
                    <Footer1 />
                </div>
                <div className='block sm:hidden'>
                    <Footer />
                </div>
            </div>
        </>
    )
}
